/**
* Baker Distributing
*/

// ==============================================
// Quickorder
// ==============================================

.page-layout-1column .quickorder-wrapper.content-wrapper {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 0;
}

.quickorder-wrapper {
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: $bp-screen-m - 1px) {
        flex-wrap: wrap;
    }

    // ==============================================
    // Quickorder Search
    // ==============================================

    .quickorder-search {
        flex-basis: 600px;

        @media (min-width: $bp-screen-m) {
            margin-right: $space-base;
        }

        @media (max-width: $bp-screen-m - 1px) {
            flex-basis: 100%;
        }

        .tabs_nav {
            justify-content: space-between;

            .tabs_nav-link {
                padding: 12px 6px;
            }

            @media (min-width: $bp-screen-s) and (max-width: $bp-screen-m - 1px) {
                justify-content: flex-start;

                .tabs_nav-link {
                    padding: 12px 16px;
                }
            }
        }

        p.empty_result {
            text-align: center;
            margin-top: 16px;
        }

        #multiple-item-search form.form {
            margin-bottom: 16px;

            > button.button {
                margin-top: 8px;
            }
        }

        .quickorder-search-bar {
            #search-input + span button > span {
                @include lib-visually-hidden;
            }
        }

        .quickorder-table {
            tr {
                height: auto !important;

                td {
                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            .product-image-wrap {
                aspect-ratio: 1 / 1;
                width: 100px;
                text-align: center;

                @media (max-width: $bp-screen-s - 1px) {
                    width: 80px;
                }

                @media (max-width: $bp-screen-xs - 1px) {
                    width: 60px;
                }

                .product-image {
                    object-fit: contain;
                    max-height: 100%;
                    display: inline-block;
                    vertical-align: top;
                }
            }

            .quickorder-item__title {
                display: block;
                line-height: 1.3;
                margin-bottom: 5px;
            }

            @media (max-width: $bp-screen-xs - 1px) {
                td {
                    padding: 10px;
                }

                .product-item-brand {
                    font-size: 13px;
                }
            }
        }

        // Algolia Styling - START
        .flex { display: flex; }
        .flex-grow { flex-grow: 1; }
        .focus\:outline-none:focus { outline: 2px solid transparent; outline-offset: 2px; }
        .p-1\.5 { padding: 0.375rem; }

        // search button
        .h-full { height: 100%; }
        .bg-transparent { background-color: transparent; }
        .bg-none { background-image: none; }
        .border-none { border-style: none; }
        .w-6 { width: 1.5rem; }
        .h-6 { height: 1.5rem; }

        #search-input + span button[type="submit"] svg {
            margin-top: 5px;
            margin-right: 0.375rem;
        }
        // Algolia Styling - END
    }

    // ==============================================
    // Quickorder Result
    // ==============================================
    .quickorder-result {
        flex: 1 1 100%;

        @media (max-width: $bp-screen-xs - 1px) {
            .action-group button.button {
                width: 100%;
            }
        }

        .quickorder-table {
            .col-image {
                width: 132px;
                text-align: center;

                @media (min-width: $bp-screen-m) and (max-width: $bp-screen-l - 1px) {
                    width: 112px;
                }

                @media (max-width: $bp-table-linearize - 1px) {
                    width: 80px;
                    float: left;
                    margin-right: $space-base;
                    margin-top: $space-xxxs;
                }

                img {
                    display: inline-block;
                    vertical-align: top;
                    max-height: 100px;

                    @media (min-width: $bp-screen-m) and (max-width: $bp-screen-l - 1px) {
                        max-height: 80px;
                    }

                    @media (max-width: $bp-table-linearize - 1px) {
                        max-height: 80px;
                    }
                }
            }

            .col-product {
                @media (max-width: $bp-table-linearize - 1px) {
                    margin-left: 96px;
                    margin-bottom: $space-base;

                    .product-item-brand {
                        font-size: 13px;
                    }
                }

                .product-attribute {
                    margin: 5px 0;
                }
            }

            .col-select {
                @media (max-width: $bp-table-linearize - 1px) {
                    margin-bottom: $space-base;
                }

                .modal {
                    .modal-header, .modal-footer {
                        background: $gray-50;
                    }

                    .modal-title {
                        padding-right: 0;
                        margin-bottom: $space-xl;
                    }

                    .modal-content {
                        padding: 0;
                    }
                }
            }

            @media (max-width: $bp-table-linearize - 1px) {
                .col-action {
                    float: right;
                    padding-top: $space-xs;
                }

                .col-qty {
                    display: inline-flex;

                    .label.p-t-xs {
                        padding-top: 0 !important;
                        line-height: 38px;
                    }
                }
            }
        }
    }
}

// ==============================================
// Quickorder Options selection
// ==============================================

.quickorder-attention {
    .modal {
        .modal-header, .modal-footer {
            background: $gray-50;
        }

        .modal-title {
            padding-right: 0;
            margin-bottom: $space-xl;
        }

        .modal-content {
            padding: 0;
        }
    }
}

.quickorder-selection {
    @media (max-width: $bp-table-linearize - 1px) {
        margin-bottom: 0;
    }

    tbody tr {
        &:first-child {
            border-top: none;

            td {
                border-top: none;
            }
        }

        &:last-child {
            border-bottom: none;

            td {
                border-bottom: none;
            }
        }

        @media (min-width: $bp-table-linearize) {
            td {
                padding: $space-s $space-base;
            }
        }

        @media (max-width: $bp-table-linearize - 1px) {
            padding: $space-xl $space-base;

            &:after {
                content: "";
                clear: both;
                display: table;
            }
        }
    }

    .col-image {
        width: 148px;
        text-align: center;
        padding-left: $space-xxl;

        @media (max-width: $bp-table-linearize - 1px) {
            width: 80px;
            float: left;
            margin-right: $space-base;
            margin-top: $space-xxxs;
            padding-left: 0;
        }

        img {
            display: inline-block;
            vertical-align: top;
            max-height: 100px;

            @media (max-width: $bp-table-linearize - 1px) {
                max-height: 80px;
            }
        }
    }

    @media (min-width: $bp-table-linearize) {
        .col-action {
            padding-right: $space-xxl;
            text-align: right;
        }
    }

    @media (max-width: $bp-table-linearize - 1px) {
        .col-product {
            min-height: 80px;
            margin-left: 96px;
            margin-bottom: $space-base;

            .product-item-brand {
                font-size: 13px;
            }

            a {
                display: block;
                line-height: 1.3;
            }

            .product-attribute {
                margin: 5px 0;
            }
        }

        .col-action {
            float: none !important;
            padding-top: 0 !important;

            button.button {
                width: 100%;
            }
        }
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .quickorder-selection_remove {
        float: right;
        margin-top: -34px;
    }
}

// ==============================================
// Quickorder Warning
// ==============================================

.quickorder-warning_icon {
    font-size: 30px !important;
    line-height: inherit !important;
}
