/**
* Baker Distributing
*/

// ==============================================
// Map
// ==============================================

$c-map-cluster-small: $blue-600;
$c-map-cluster-medium: $c-warning;
$c-map-cluster-large: $gray-800;

.map-marker_store {
    position: absolute;
    width: 34px;
    left: calc(50% - 17px);
    top: calc(100% + 2px);
    padding: 2px 0;
    background: $c-brand;
    border-radius: $radius-base;
    color: $c-white;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    transition: 200ms all;
    transform: translateY(-10px);
    opacity: 0;
    pointer-events: none;
}

.map-marker--current .map-marker_store {
    background: #a60d26;
}

.map-marker:not(.map-marker--pinned):hover .map-marker_store,
.map-marker--pinned .map-marker_store,
.map-marker--hovered .map-marker_store,
.map-marker--id-visible .map-marker_store {
    transform: translateY(0);
    opacity: 1;
}

.map-marker_pin {
    transition: all 200ms;
}

.map-marker:not(.map-marker--pinned):hover .map-marker_pin,
.map-marker--pinned .map-marker_pin,
.map-marker--hovered .map-marker_pin {
    transform: scale(1.2) translateY(-4px);
}

.map-marker--hovered,
.map-marker:hover {
    z-index: $z-index-5;
}

.map-cluster {
    appearance: none;
    border: 0;
    background: none;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c-white;
    font-weight: 500;
    position: absolute;
    left: 0;
    top: 0;
    z-index: $z-index-1;
    transform: translate(-50%,-50%);
}

.map-cluster:before, .map-cluster:after {
    display: block;
    content: '';
    position: absolute;
    z-index: -1;
    opacity: .4;
    width: inherit;
    height: inherit;
    background: inherit;
    border-radius: inherit;
    transform: scale(1.4);
}

.map-cluster:before {
    transform: scale(1.7);
}

.map-cluster--small {
    width: 25px;
    height: 25px;
    background: $c-map-cluster-small;
    font-size: 14px;
}

.map-cluster--medium {
    width: 30px;
    height: 30px;
    background: $c-map-cluster-medium;
}

.map-cluster--large {
    width: 35px;
    height: 35px;
    background: $c-map-cluster-large;
    font-size: 14px;
}

.map-store-info {
    box-sizing: border-box;
    width: 324px;
    padding: $space-base;
    border-radius: $radius-l;
    box-shadow: $box-shadow-base;
    position: absolute;
    bottom: calc(100% + 6px);
    left: calc(50% - 162px);
    background: $c-white;
    transition: 200ms all;
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;
    cursor: default;
    font-size: $font-size-s;
    z-index: $z-index-9;
}

.map-store-info--visible {
    opacity: 1;
    pointer-events: auto;
}

.map-store-info:after {
    background: $c-white;
    box-shadow: -2px 2px 2px 0 black(0.2);
    content: '';
    height: 15px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 15px;
}

.map-store-info .branch-name {
    margin-right: 26px;
}


.map-store-info_close {
    @include lib-button-reset();
    @include lib-button-icon(
            $icon-close,
        $_icon-font-color       : $gray-700,
        $_icon-font-size        : 18px,
        $_icon-font-line-height : 18px,
        $_icon-font-text-hide   : true
    );
    position: absolute;
    right: 0;
    top: 0;
    padding: $space-base;

    &:hover {
        &::before {
            color: darken($c-link, 10%);
        }
    }
}

.map-store-info_close span {
    display: none
}


