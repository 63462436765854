/**
* Baker Distributing
*/

// ==============================================
// Header Main Nav
// ==============================================

.header-main-nav {
    @media (max-width: $bp-screen-m - 1px) {
        display: none;
    }

    #header-main-nav\.app {
        > nav > ul {
            display: flex;

            @media (max-width: $bp-screen-l - 1px) {
                justify-content: space-around;
            }

            > li {
                transition: background-color .3s ease-in-out;
                font-size: 14px;
                line-height: 18px;

                @media (min-width: $bp-screen-l) {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                }

                &.active {
                    background-color: $red-900;

                    .nav-item-dropdown {
                        display: block;
                    }
                }

                > span, > a {
                    display: block;
                    color: $c-white;
                    text-align: center;
                    padding: 10px 15px;
                }

                > a:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

// ==============================================
// Header Main Nav - Dropdown
// ==============================================

.header-main-nav {
    .nav-item-dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 500;
        width: 100%;
        background: $c-white;

        .nav-item-dropdown-header {
            display: flex;
            padding: 20px;
            border-bottom: 1px solid #707070;

            h2 {
                display: inline-block;
                vertical-align: top;
                font-size: 24px;
                line-height: 38px;
                margin-right: 20px;
                margin-bottom: 0;

                @media (max-width: $bp-screen-l - 1px) {
                    font-size: 26px;
                }
            }

            .nav-logos {
                margin-left: auto;

                ul {
                    display: flex;
                    align-items: center;
                    column-gap: 15px;

                    li img {
                        max-height: 38px;
                        width: auto;

                        @media (max-width: $bp-screen-l - 1px) {
                            max-width: 80px;
                        }
                    }
                }
            }
        }

        .nav-item-dropdown-featured {
            border-bottom: 1px solid #707070;
            padding: 10px 20px;
            background: $gray-100;

            > ul {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 20px;

                li a {
                    color: rgba(0, 0, 0, 0.87);
                    text-decoration: underline;
                }
            }
        }

        .nav-item-dropdown-content {
            display: flex;

            .nav-item-dropdown-sidebar {
                padding: 20px 0;
                border-right: 1px solid #707070;
                flex: 0 0 20%;
                max-height: 600px;
                overflow-y: auto;

                ul {
                    li {
                        a, span {
                            display: block;
                            color: rgba(0, 0, 0, 0.87);
                            font-size: 16px;
                            font-weight: 500;
                            padding: 10px 20px;
                            transition: background-color .3s ease-in-out;

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        &.active {
                            a, span {
                                background-color: $gray-100;
                            }
                        }
                    }
                }
            }

            .nav-item-dropdown-main {
                display: none;
                flex: 1;
                justify-content: space-between;
                column-gap: 40px;
                padding: 20px 20px 20px 40px;
                max-height: 420px;
                overflow-y: auto;

                &.active {
                    display: flex;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    flex: 1;
                    align-content: flex-start;
                    gap: 10px 40px;
                    max-height: 560px;

                    li {
                        a {
                            color: rgba(0, 0, 0, 0.87);
                        }

                        &.child-view-all {
                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                @media (max-width: $bp-screen-l - 1px) {
                    .nav-ad-wrap {
                        display: none;
                    }
                }
            }
        }
    }
}
