/**
* Baker Distributing
*/


// ==============================================
// Logo Wrapper
// ==============================================

.logo-wrapper {
    z-index: 5;
}

// ==============================================
// Logo
// ==============================================

a.logo {
    display: inline-block;
    vertical-align: top;
}

.logo img {
    display: block;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .logo-wrapper {
        position: relative;
        margin-left: 15px;
    }

    .header-logo_baker,
    .header-logo_ice-design,
    .header-logo_baker-fcs,
    .header-logo_makdad,
    .header-logo_comspec {
        height: 32px;
        width: auto;
        max-width: 150px;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .logo-wrapper {
        order: 2;
        text-align: left;
        margin-right: 40px;
    }

    .header-logo_baker {
        width: 162px;
        height: 40px;
    }

    .header-logo_baker-fcs {
        width: 219px;
        height: 40px;
    }

    .header-logo_ice-design {
        width: 154px;
        height: 40px;
    }

    .header-logo_makdad {
        width: 120px;
        height: 54px;
    }

    .header-logo_comspec {
        width: 151px;
        height: 60px;
    }
}
