/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Font Family
// ==============================================

$font-family-icon:    'Material Icons' !default;
$font-family-mono:    Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-sans:    'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family-serif:   Georgia, 'Times New Roman', Times, serif !default;
$font-family-special: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;

$font-family-base: $font-family-sans !default;


// ==============================================
// Font Size
// ==============================================

$font-size-xxxxxxl: 60px;
$font-size-xxxxxl:  48px;
$font-size-xxxxl:   36px;
$font-size-xxxl:    24px;
$font-size-xxl:     20px;
$font-size-xl:      18px;
$font-size-l:       16px;

$font-size-base: 14px;

$font-size-s:   13px;
$font-size-xs:  12px;
$font-size-xxs: 11px;


// ==============================================
// Font Weight
// ==============================================

$font-weight-light:    300;
$font-weight-normal:   400;
$font-weight-semibold: 500;
$font-weight-bold:     500;


// ==============================================
// Letter Spacing
// ==============================================

$letter-spacing: 0.04rem;


// ==============================================
// Line Height
// ==============================================

$line-height-xxl:  1.8;
$line-height-xl:   1.7;
$line-height-l:    1.6;

$line-height-base: 1.5;

$line-height-s:    1.4;
$line-height-xs:   1.3;
$line-height-xxs:  1.2;


// ==============================================
// Margin Bottom
// ==============================================

$margin-bottom: 1.5em;


// ==============================================
// Border
// ==============================================

$border-width__base: 1px;


// ==============================================
// Indent
// ==============================================

$indent__base: 20px;
