/**
* Baker Distributing
*/


// ==============================================
// Store Locator
// ==============================================

body.store-locator .column.main {
    width: 100%;
}

div.store-locator {
    display: flex;
    flex-wrap: nowrap;
}


// ==============================================
// Store Locator - Map
// ==============================================

.store-locator_map {
    flex: 1 1 100%;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .store-locator_map {
        display: none;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .store-locator_map {
        height: 89vh;
        margin-top: -58px;
        margin-right: -20px;
    }

}


// ==============================================
// Store Locator - Search
// ==============================================

.store-locator_search {
    flex-basis: 520px;
    padding-right: 20px;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .store-locator_search {
        flex-basis: 100%;
    }
}

.store-locator_search .store-locator_search-input {
    margin: 0;
    padding-right: 35px;
    padding-left: $space-xs;
    position: static;
    border-radius: $radius-base;
    height: 38px;
}

.store-locator_search form {
    display: inline-block;
    width: 100%;
}

.store-locator_search .action.search {
    display: inline-block;
    @include lib-button-icon(
        $_icon-font-content: $icon-search,
        $_icon-font-text-hide: true,
        $_icon-font-color: $gray-800
    );
    @include lib-button-reset();
    position: relative;
    float: right;
    margin-top: -30px;

    &:focus::before {
        color: $gray-900;
    }
}

.store-locator_search .input-hint {
    margin-top: $space-xs;
    min-height: 0;
}

.store-locator_search-location {
    @include lib-icon-font(
                    $icon-mylocation,
            $_icon-font-size: 18px,
            $_icon-font-position: before,
            $_icon-font-margin: -3px 6px 0 0
    )
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .store-locator_search {
        width: 100%;
        padding: 0;
    }

}

// ==============================================
// Store Locator - Search Predictions
// ==============================================

.store-locator_predictions {
    position: absolute;
    top: 38px;
    left: 0;
    width: 100%;
    z-index: $z-index-1;
    background: $c-white;
    border: 1px solid $c-border;
    box-shadow: $box-shadow-base;

    li {
        line-height: 1.2;
        padding: $space-xs;
        border-bottom: 1px solid $c-border;
        cursor: pointer;
        transition: $transition-background;

        &:hover {
            background: $c-background;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

// ==============================================
// Store Locator - Back Top
// ==============================================

@media (min-width: $bp-screen-m) {

    body.store-locator .back-top {
        display: none;
    }

}
