/**
* Baker Distributing
*/


// ==============================================
// Account profile page
// ==============================================

$account-profile-grid-gap: 40px;
$account-profile-grid-col-2-width: 280px;

@media (min-width: $bp-screen-l) {
    .account-profile-grid > .col-1 {
        float: left;
        width: calc(100% - #{($account-profile-grid-gap + $account-profile-grid-col-2-width)});
        max-width: 900px;
    }

    .account-profile-grid > .col-2 {
        float: right;
        width: ($account-profile-grid-gap + $account-profile-grid-col-2-width);
        padding-left: $account-profile-grid-gap;
    }
}

@media (min-width: $bp-screen-s) {
    .organization_tab .field {
        width: 50%;
    }

    .operations_tab .field {
        width: 50%;
    }
}

.save_disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed;
}

.edit_disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.account-profile-grid .accordion_content .field {
    max-width: 448px;
}

[type='radio'] + .accordion_radio {
    &::after {
        opacity: 0 !important;
        animation: none !important;
    }
}

[type='checkbox'] + .accordion_checkbox {
    &::before {
        opacity: 0 !important;
        animation: none !important;
    }
}

.account-profile-label {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    letter-spacing: 0.04rem;
    line-height: 10px;
    font-size: 13px;
}

.account-profile-input--blue {
    border-bottom: 1px solid #136cea !important;
}

.account-profile-label--blue {
    color: #136cea !important;
    font-weight: 500 !important;
}

.account-profile-permissions-widget-text {
    color: $gray-600;
}
