/**
* Baker Distributing
*/


// ==============================================
// Simple Loading
// ==============================================

.simple-loading {
    width: 30px;
    height: 30px;
    margin: 10px auto;
    background-image: $icon-loading;
    animation: rotate-clockwise 4s linear infinite;
}

.simple-loading-message {
    text-align: center;
    font-size: 16px;
}
