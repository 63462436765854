/**
* Baker Distributing
*/

// ==============================================
// AHRI System View page
// ==============================================

.ahri-details .page.messages {
    padding: 0;
}

.ahri-details .page-title-wrapper .page-title {
    font-weight: $font-weight-normal;
    font-size: 28px;
    line-height: 1;
}

.ahri-details_small-tables {
    display: flex;
    flex-flow: row wrap;
}

.ahri-details_small-table {
    width: 100%;
}

@media (min-width: $bp-screen-m) {
    .ahri-details_small-table {
        margin-right: $space-base;
    }
}

.ahri-details_small-table th,
.ahri-details_small-table td {
    width: 50%;
}

@media (min-width: $bp-screen-m) {
    .ahri-details_small-table {
        flex: 1;
    }
}

.ahri-details_totals {
    background-color: $c-white;
    border: 1px solid $c-border-light;
    padding: $space-base;
}

@media (min-width: $bp-screen-m) {
    .ahri-details_totals {
        margin-bottom: 98px;
    }
}

.ahri-details_totals-wrapper {
    position: relative;
    margin-bottom: $space-base;
}

.ahri-details_totals-wrapper--sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
}

@media (min-width: $bp-screen-m) {
    .ahri-details_totals-wrapper {
        width: 300px;
        position: sticky;
        float: right;
        top: $space-base;
        bottom: $space-base;
        margin-bottom: 58px;
    }
}

.ahri-details_item {
    background-color: $c-white;
    border: 1px solid $c-border-light;
    margin-bottom: $space-base;
    padding: $space-base $space-xs;
}

@media (min-width: $bp-screen-xs) {
    .ahri-details_item {
        display:flex;
    }
}

.ahri-details_item-picture {
    flex: 0 0 120px;
    margin-right: $space-base;
}
.ahri-details_item-picture img {
    max-height: 150px;
    margin: 0 auto;
}

@media (max-width: $bp-screen-xs - 1px) {
    .ahri-details_item-picture img {
        margin: 0 auto $space-xs;
        max-width: 200px;
    }
}

@media (min-width: $bp-screen-l) {
    .ahri-details_item-essentials {
        display: flex;
        flex-wrap: wrap;
    }
}

.ahri-details_item-essentials > div {
    margin: 0 $space-base $space-xs $space-base;
}

.ahri-details_item-name-num {
    flex: 0 1 450px;
    min-width: 200px;
}

.ahri-details_inventory {
    flex: 0 0 auto;
}

.ahri-details_inventory .inventory-wrapper--compact {
    min-height: initial;
}

.ahri-details_price {
    flex: 0 0 auto;
}

@media (min-width: $bp-screen-l) {
    .ahri-details_price {
        text-align: right;
    }
}

.system-info_top {
    display: flex;
    flex-wrap: wrap;
}

.system-info_top > div {
    background: $c-white;
    margin-bottom: $space-base;
    flex: 0 0 auto;
}

// ==============================================
// AHRI System View - Top
// ==============================================

@media (min-width: $bp-screen-m) {
    .ahri-details_equipments--with-sidebar {
        margin-right: 316px;
    }
}

.ahri-details_print {
    margin-top: -50px;
}

// ==============================================
// AHRI System View page - Totals
// ==============================================

.ahri-details_totals .equipment > th {
    font-size: $font-size-s
}

.ahri-details_totals .equipment > * {
    padding-bottom: $space-s;
    white-space: nowrap;
}

.ahri-details_totals .section > th {
    font-weight: $font-weight-semibold;
}

// ==============================================
// AHRI System View - Top
// ==============================================

@media (min-width: $bp-screen-m) {
    .ahri-details_cross-sell--with-sidebar {
        margin-right: 316px;
    }
}
