#search.tabs_item.panel {
    div.flex {
        border: 1px solid $gray-200;
        height: 36px;
    }

    input#search-input {
        border: none;
        background: #fff;
    }

    .quickorder-table {
        margin-top: 1rem;
        tr {
            height: 190px;
        }
    }
}
