/**
 * Baker Distributing
 *
 * @author     Brendan Falkowski (http://gravitydept.com)
 * @copyright  Copyright 2018 Gravity Department
 * @license    All rights reserved.
 */


// ==============================================
// Vars
// ==============================================

$c-stepper-button-base: $c-action-secondary-base !default;
$c-stepper-button-hover: $c-action-secondary-hover !default;
$c-stepper-button-active: $c-action-secondary-active !default;

$stepper-button-size-base: 40px !default;
$stepper-button-size-l: 48px !default;

$stepper-icon-size: 24px !default;

$stepper-input-size-base: 60px !default;
$stepper-input-size-l: 70px !default;


// ==============================================
// Stepper
// ==============================================

.stepper {
    width: ($stepper-button-size-base + $stepper-input-size-base + $stepper-button-size-base);
    margin: 0 auto;
}

.stepper_wrap {
    display: flex;
}

// ----------------------------------------------
// Modifier: Flex

.stepper--flex {
    width: auto;
}

// ----------------------------------------------
// Modifier: Large

.stepper--l {
    min-width: ($stepper-button-size-l + $stepper-input-size-l + $stepper-button-size-l);
}


// ==============================================
// Stepper - Label
// ==============================================

.stepper_label {
    display: inline-block;
    margin-bottom: 5px;
}


// ==============================================
// Stepper - Liner
// ==============================================

.stepper_liner {
    &::after {
        @include clearfix;
    }
}

// ----------------------------------------------
// Modifier: Flex

.stepper--flex .stepper_liner {
    display: flex;
}


// ==============================================
// Stepper - Input
// ==============================================

.stepper_input {
    float: left;
    width: $stepper-input-size-base;
    height: $stepper-button-size-base;
    padding: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    font-size: $font-size-xl;
    text-align: center;

    &:focus {
        border-color: $c-input-border;
    }
}

// ----------------------------------------------
// Modifier: Flex

.stepper--flex .stepper_input {
    flex: auto;
}

// ----------------------------------------------
// Modifier: Large

.stepper--l .stepper_input {
    width: $stepper-input-size-l;
    height: $stepper-button-size-l;
}


// ==============================================
// Stepper - Button
// ==============================================

.stepper_button {
    @include image-replacement;
    position: relative;
    float: left;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 2px;
    background: $c-stepper-button-base;
    user-select: none;

    &:hover {
        background: $c-stepper-button-hover;
    }

    &:focus,
    &:active {
        background: $c-stepper-button-active;
    }

    &:focus {
        box-shadow: none;
    }

    &::before {
        @include image-replacement-override;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: $stepper-icon-size;
        height: $stepper-icon-size;
        margin-top: ($stepper-icon-size / -2);
        margin-left: ($stepper-icon-size / -2);
    }
}

// ----------------------------------------------
// Modifier: Down

.stepper_button--down {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &::before {
        @include svg (stepper-down);
    }
}

// ----------------------------------------------
// Modifier: Large

.stepper--l .stepper_button {
    width: $stepper-button-size-l;
    height: $stepper-button-size-l;
}

// ----------------------------------------------
// Modifier: Up

.stepper_button--up {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &::before {
        @include svg (stepper-up);
    }
}
