/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

$products-grid-gutter: $space-l;
$products-grid-padding: $space-base;
$products-grid-border-color: $c-border-light;


// ==============================================
// Products Grid
// ==============================================

//.products-grid {}


// ==============================================
// Products Grid - Product Items
// ==============================================

.products-grid .product-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: ($products-grid-gutter * -1);
    margin-bottom: $gap;
    margin-left: ($products-grid-gutter * -1);
}


// ==============================================
// Products Grid - Product Item
// ==============================================

.products-grid .product-item {
    flex: 1 0 220px;
    background: $c-white;
    border: 1px solid $products-grid-border-color;
    margin-top: $products-grid-gutter;
    margin-left: $products-grid-gutter;
    padding: $products-grid-padding;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .products-grid .product-item {
        flex-basis: 240px;
    }

}


// ----------------------------------------------

@media (min-width: $bp-screen-l) {

    .products-grid .product-item {
        flex-basis: 250px;
    }

}

// ==============================================
// Products Grid - Product Item Photo
// ==============================================

.products-grid .product-item-photo img {
    margin-bottom: $space-xs;
}

// ==============================================
// Products Grid - Product Item Link
// ==============================================

a.product-item-link {
    display: block;
    color: $c-text;
    margin-bottom: $space-xs;
}

// ==============================================
// Products Grid - Product Item Name
// ==============================================

.products-grid .product-item-name {
    font-size: 14px;
    margin-bottom: 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-xs) {

    .products-grid .product-item-name {
        overflow: hidden;
        height: 58px;
    }

}


// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .products-grid .product-item-actions {
        margin: $space-base 0 0 0;
    }

}

// ==============================================
// Products Grid - Product Photo
// ==============================================


// ==============================================
// Products Grid - Compare
// ==============================================

.products-grid .product-item-actions .tocompare {
    display: none;
}

.products-grid .product-item-details {
    text-align: left;
}
