// ==============================================
// Algolia Products Grid
// ==============================================

.catalog-app__main {
    .column.main {
        .algolia-product-list {
            margin-bottom: 30px;

            @media all and (max-width: 768px) {
                margin-bottom: 14px;
            }

            .products-list > div {
                width: 100%;
            }

            .product-list-item {
                width: 100%;
                padding: 16px;
                margin-bottom: 20px;
                border: 1px solid $gray-100;
                background: #fff;

                .product-item-info {
                    display: flex;

                    .product-item-photo {
                        height: 200px;
                        flex: 0 1 240px;
                    }
                }
            }
        }
    }
}

.filter-options,
.categories-content {
    input {
        opacity: 0;
    }

    [type="checkbox"] + label::before {
        top: 24% !important;
        left: 10px;
    }
}

.categories-content {
    [type="checkbox"] + label::after {
        top: 1px !important;
    }

    [type='checkbox']:checked + label,
    [type='checkbox']:indeterminate + label {
        &:before {
            display: none;
        }
    }

    .filter-group {
        display: none;

        &.active {
            display: block;
        }

        .btn-toggle {
            width: 15px;
            height: 27px;
            padding: 0;
            margin-right: 5px;
            position: relative;
            display: inline-block;
            vertical-align: top;

            &:after {
                content: '+';
                position: absolute;
                top: 45%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

            &.active {
                &:after {
                    content: '-';
                }
            }
        }
    }

    ul > li > div.flex > div {
        padding-top: 2px;
    }
}

.filter-options {
    [type="checkbox"] + label::after {
        top: -1px !important;
    }
}

.catalog-app__main .column.main {
    .no-results {
        text-align: center;
        min-height: 300px;
        padding: 40px 0;
        margin-left: 20px;

        @media (max-width: $bp-screen-m - 1px) {
            min-height: 0;
            padding: 30px 0;
            margin-left: 0;
        }

        h2 {
            font-size: $font-size-xxxl;
            margin: 0;

            + p {
                margin-top: $space-base;
                font-size: $font-size-xxl;
            }
        }
    }

    .algolia-product-list ~ .toolbar-wrap--bottom {
        margin-bottom: 26px;

        .toolbar.toolbar-products {
            margin: 0;

            @media (max-width: $bp-screen-m - 1px) {
                margin-top: 0;
            }
        }
    }
}

.algolia-button {
    background: white;
    border: none;
    margin: 0;

    span {
        @include lib-visually-hidden;
    }
}

.inventory-content {
    .branch-selector-options .branch-selector-option {
        position: relative;

        > input[type='radio'] {
            margin-left: 0;
            position: absolute;
            top: 3px;
            left: 0;

            + label {
                display: block;
                margin-left: 0;

                &::before {
                    top: 1px;
                }

                &::after {
                    display: none;
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: $space-xs;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .catalog-app__main {
        display: flex;

        .product-item-name {
            font-size: 16px;
        }

        .product-item-actions {
            margin: 16px 0 8px 0;
        }

        .catalog-app__sidebar {
            margin-right: 20px;

            .sidebar.sidebar-main {
                margin-top: 10px;
                margin-right: 20px;
                margin-left: -4px;
            }
        }

        .column.main {
            margin-right: 20px;
            width: 100%;

            #product-collection-controls {
                margin-left: -15px;
            }
            .toolbar-wrap--top {
                .toolbar.toolbar-products {
                    .view-mode {
                        position: relative;
                        .modes {
                            margin-top: 10px;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
            .algolia-product-list {
                .list {
                    margin-left: -16px;
                }
            }
        }
    }

    #app-wrapper .page-main,
    #app-wrapper-brands .page-main {
        padding: 0;
    }

    #branch_inventory_filter .field {
        margin-bottom: 0;
    }

    // .cat-tab-like {
    //     border-left: 1px $c-gray-lighter solid;
    //     margin: 0;
    //     margin-left: 8px;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    // }
}

@media (min-width: $bp-screen-m) {
    .catalog-app__main .product-item-details .product-item-name {
        overflow: hidden;
        height: 72px;
    }
}

@media (min-width: $bp-screen-xxl) {
    .catalog-app__main .column.main .algolia-product-list #products-collection {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

@media (min-width: $bp-screen-xxxl) {
    .catalog-app__main .column.main .algolia-product-list #products-collection {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .inventory-content {
        .branch-selector-options .branch-selector-option {
            > input[type='radio'] {
                top: 9px;

                + label {
                    padding-top: 6px;
                    padding-bottom: 6px;

                    &::before {
                        top: 7px;
                    }
                }
            }

            &:not(:last-child) {
                margin-bottom: $space-xxs;
            }
        }
    }
}
