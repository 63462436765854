/**
* Baker Distributing
*/


// ==============================================
// Button
// ==============================================

.button {
    @include button;
}

.button--secondary {
    @include button-style (
            $background         : $c-white,
            $background--hover  : $c-white,
            $background--active : $c-white,
            $color              : $blue-700
    )
}

a.button,
a.button--secondary {
    padding-top: 9px;

    &.button--s {
        padding-top: 5px;
    }
}

// Disabled

.button:disabled,
.button.disabled {
    @include button-style (
            $background         : $gray-100,
            $background--hover  : $gray-100,
            $background--active : $gray-100,
            $color              : $gray-300
    );
    box-shadow: none;
}


// Loading

.button.button--loading {
    @include button-style (
        $background         : $gray-100,
        $background--hover  : $gray-100,
        $background--active : $gray-100,
        $color              : $gray-300
    );
    box-shadow: none;

    &::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzAuNCAzMC40Ij48c3R5bGU+LnN0MHtmaWxsOiNjODEwMmV9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNLTMzNS02MTMuOGgzLjNjMC02IDQuOS0xMC45IDEwLjktMTAuOSAzLjMgMCA2LjQgMS41IDguNSA0LjFsMi42LTIuMWMtMi43LTMuMy02LjctNS4zLTExLjEtNS4zLTcuOSAwLTE0LjIgNi4zLTE0LjIgMTQuMnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMzNiA2MjkpIi8+PHBhdGggY2xhc3M9InN0MCIgZD0iTS0zMDcuNy02MTguM2MuMS4zLS4yLjUtLjUuNGwtNi41LTEuNGMtLjMtLjEtLjMtLjMtLjEtLjRsNi41LTUuMmMuMi0uMi40LS4xLjQuMmwuMiA2LjR6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYgNjI5KSIvPjxnPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zNjYuNiA2NjUuOGgzLjNjMC02IDQuOS0xMC45IDEwLjktMTAuOSAzLjMgMCA2LjQgMS41IDguNSA0LjFsMi42LTIuMWMtMi43LTMuMy02LjctNS4zLTExLjEtNS4zLTcuOSAwLTE0LjIgNi4zLTE0LjIgMTQuMnoiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMTk4IDM0MC41KSIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOTMuOSA2NjEuM2MuMS4zLS4yLjUtLjUuNGwtNi41LTEuNGMtLjMtLjEtLjMtLjMtLjEtLjRsNi41LTUuMmMuMi0uMi40LS4xLjQuMmwuMiA2LjR6IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDE5OCAzNDAuNSkiLz48L2c+PC9zdmc+);
        content: '';
        float: left;
        margin-right: $space-s;
        display: inline;
        height: 24px;
        width: 24px;
        animation: rotate-clockwise 4s linear infinite;
    }
}


// ==============================================
// Flat Button
// ==============================================

.flat-button {
    @include flat-button;
}

.flat-button--secondary {
    @include button-style (
            $background         : transparent,
            $background--hover  : $gray-50,
            $background--active : $gray-100,
            $color              : $c-secondary
    )
}

a.flat-button,
a.flat-button--secondary {
    padding-top: 9px;

    &.button--s {
        padding-top: 5px;
    }
}

// Disabled

.flat-button:disabled,
.flat-button.disabled {
    @include button-style (
            $background         : transparent,
            $background--hover  : transparent,
            $background--active : transparent,
            $color              : $gray-300
    );
    box-shadow: none;
}

// ==============================================
// Context: .form--contrast
// ==============================================
.form--contrast .flat-button {
    @include button-style(
            $background         : transparent,
            $background--hover  : $gray-800,
            $background--active : $gray-700,
            $color              : $blue-400
    );
}

// ==============================================
// Button sizes
// ==============================================


.button--s {
    font-size: 13px;
    padding: $space-xxs $space-xs;
    height: 30px;
}

.button--l {
    font-size: $font-size-l;
    height: 42px;
}

.button--xl {
    font-size: $font-size-xl;
    height: 46px;
    padding: $space-xs $space-xxl;
}

.button--block {
    width: 100%;
}

@media (min-width: $bp-screen-m) {

    .button--block-desktop {
        width: 100%;
    }
}

@media (min-width: $bp-screen-s) and (max-width: $bp-screen-m - 1px) {

    .button--block-tablet {
        width: 100%;
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .button--block-mobile {
        width: 100%;
    }
}


// [todo] Change the templates and remove temporary code
.secondary .action,
.action-secondary {
    @include flat-button;
}
.account .table-wrapper .action
.primary a,
.secondary a {
    @include flat-button;
}

.action.primary:not(.modal-action),
.action-primary {
    @include button;
}
