/**
* Baker Distributing
*/

[data-content-type='button-item'] {
    max-width: 100%;
    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
    [data-element='empty_link'] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        box-shadow: none;
        box-sizing: border-box;
        display: inline-block;
        margin: 0 $space-base $space-base 0;
    }

    &.pagebuilder-button-primary,
    &.pagebuilder-button-secondary {
        @include button();
        margin: 0 $space-base $space-base 0;
    }

    &.pagebuilder-button-secondary {
        @include button-style (
            $background         : $c-white,
            $background--hover  : $c-white,
            $background--active : $c-white,
            $color              : $blue-700
        )
    }
}
