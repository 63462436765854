/**
* Baker Distributing
*/

[data-appearance='collage-right'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}
