/**
* Baker Distributing
*/

// ==============================================
// Order View - Status Badge
// ==============================================

.order-view--status.show-print {
    display: none;
}

body.sales-order-print .order-view--status.show-print {
    display: table-cell !important;
}

body.new-sales-printorder .order-view--status.show-print {
    display: table-cell !important;
}

body.new-sales-printorder .badge-group {
    display: none;
}

body.sales-order-printorder .order-view--life-cycle {
    display: none;
}

// ==============================================
// Order History - Filters
// ==============================================
.order-history-filters-form {
    max-width: 50rem;

    .input-text.hidden {
        display: none;
    }

    .branch-id-field {
        position: relative;
        flex: 0 0 calc(50% - 8px);

        .filter-dropdown {
            position: absolute;
            z-index: $z-index-1;
            background: $c-white;
            top: 100%;
            left: 0;
            width: 100%;
            box-shadow: $box-shadow-base;
            max-height: 500px;
            overflow-y: auto;
            display: none;

            &.active {
                display: block;
            }

            @media (max-width: $bp-screen-xs - 1px) {
                max-height: 300px;
            }
        }

        .filter-dropdown-options {
            .filter-dropdown-option {
                padding: 10px;
                border-bottom: 1px solid $c-border;
                cursor: pointer;
                transition: background-color $transition-base;

                &:hover {
                    background-color: $dropdown-list-item__hover;
                }
            }
        }

        #order-branch-id-filter {
            padding-right: $space-xxl;

            &:not(.hasValue) + .btn-clear {
                display: none;
            }
        }

        .btn-clear {
            position: absolute;
            z-index: $z-index-1;
            top: 0;
            right: 0;
            color: $gray-600;
            line-height: 38px;
            cursor: pointer;
            padding: 0 $space-xxs;
            transition: opacity $transition-base;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
