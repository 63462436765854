/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

$header-icons-color:       $c-white;


// ==============================================
// Page Header
// ==============================================

.page-header {
    background-color: $c-header-background;
    position: relative;
    z-index: 12;
}

@media (max-width: $bp-screen-m - 1px) {
    .page-header {
        display: flex;
    }
}

.form.minisearch {
    max-width: none;
}

.quote-dropdown-content,
.quicklist-dropdown-content {
    padding: 16px;
}

.quote-dropdown-content:before,
.quicklist-dropdown-content:before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    right: 22px;
}

.quote-dropdown-content:after,
.quicklist-dropdown-content:after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    right: 24px;
}

.header-quotes_item,
.header-quicklists_item {
    margin-bottom: 8px;
}


.header-top .header.links {
    > li.dropdown {
        @media (min-width: $bp-screen-m) {
            > a {
                &:after,
                &.active:after {
                    font-size: 16px;
                    vertical-align: top;
                }

                &.active {
                    background: $gray-800;
                }
            }
        }

        .dropdown-content.dropdown-content--right {
            @media (min-width: $header-bp-l) {
                right: 5px;

                &:before {
                    right: 8px;
                }

                &:after {
                    right: 10px;
                }
            }

            @media (max-width: $header-bp-l - 1) {
                right: 0;
            }

            @media (min-width: $header-bp-m) and (max-width: $header-bp-l - 1) {
                &:before {
                    right: 7px;
                }

                &:after {
                    right: 9px;
                }
            }

            @media (max-width: $header-bp-m - 1) {
                &:before {
                    right: 4px;
                }

                &:after {
                    right: 6px;
                }
            }
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .header.sign-in {
        display: none;
    }
}


// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .page-header {
        margin-bottom: 16px;
    }

    .header-top .header.links {
        > li.dropdown {
            display: block;

            .dropdown-trigger:after {
                content: '';
            }
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .page-header {
        border: 0;
        margin-bottom: $space-base;
    }

    .page-header .switcher {
        display: inline-block;
    }
}


// ==============================================
// Header Content
// ==============================================

.header.content {
    @include lib-clearfix;
    flex-wrap: wrap;

    @media (min-width: $bp-screen-m) {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: $space-l;
    }

    @media (max-width: $bp-screen-m - 1px) {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 10px 15px;
        z-index: $z-index-10;
        box-shadow: $box-shadow-base;

        .nav-toggle {
            position: relative;
            top: auto;
            left: auto;
        }
    }
}

// ==============================================
// Header Top Nav
// ==============================================

.header-top {
    display: none;
    background: $gray-900;

    @media (min-width: $bp-screen-m) {
        display: block;
    }

    .header-top-nav {
        display: inline-block;
        vertical-align: top;

        > ul {
            display: flex;

            > li {
                > a {
                    padding: 5px 20px;
                    color: $c-white;
                    display: block;
                    font-size: 13px;
                    line-height: 20px;
                    -webkit-transition: background 0.1s;
                    transition: background 0.1s;

                    @media (min-width: $header-bp-m) and (max-width: $header-bp-l - 1) {
                        padding: 5px 8px;
                    }

                    @media (max-width: $header-bp-m - 1) {
                        padding: 5px 10px;
                    }

                    &:hover {
                        text-decoration: none;
                        background: $gray-800;
                    }
                }

                &.mobile-only {
                    display: none;
                }

                &.resources-dropdown {
                    > a {
                        padding: 5px 15px 5px 20px;

                        @media (min-width: $header-bp-m) and (max-width: $header-bp-l - 1) {
                            padding: 5px 8px 5px 13px;
                        }

                        @media (max-width: $header-bp-m - 1) {
                            padding: 5px 5px 5px 10px;
                        }

                        &:after {
                            font-size: 16px;
                            vertical-align: top;
                        }

                        &.active {
                            background: $gray-800;
                        }
                    }

                    .resources-dropdown-content {
                        padding: 10px 20px;

                        &:before {
                            @include triangle($direction: up, $size: 8px, $color: $c-border);
                            top: -8px;
                            left: 79px;

                            @media (min-width: $header-bp-m) {
                                left: 83px;
                            }
                        }

                        &:after {
                            @include triangle($direction: up, $size: 6px, $color: $c-white);
                            top: -6px;
                            left: 81px;

                            @media (min-width: $header-bp-m) {
                                left: 85px;
                            }
                        }

                        li {
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                &.new {
                    > a {
                        background: $c-library-yellow-4;
                        color: $gray-900;

                        @media (min-width: $header-bp-m) {
                            display: flex;
                            padding: 0 20px 0 0;
                            height: 100%;
                            line-height: 30px;

                            &::before {
                                content: 'New!';
                                background: $c-library-yellow-5;
                                padding: 0 10px;
                                margin-right: 20px;
                            }
                        }

                        &:hover {
                            background: $c-library-yellow-5;
                        }
                    }
                }
            }
        }
    }

    .header.links {
        display: flex;
        float: right;

        > li {
            > a {
                padding: 5px 20px;
                color: $c-white;
                display: block;
                font-size: 13px;
                line-height: 20px;
                -webkit-transition: background 0.1s;
                transition: background 0.1s;

                @media (min-width: $header-bp-m) and (max-width: $header-bp-l - 1) {
                    padding: 5px 8px;
                }

                @media (max-width: $header-bp-m - 1) {
                    padding: 5px 10px;
                }

                &:hover {
                    text-decoration: none;
                    background: $gray-800;
                }
            }

            &.dropdown {
                > a {
                    padding: 5px 15px 5px 20px;

                    @media (min-width: $header-bp-m) and (max-width: $header-bp-l - 1) {
                        padding: 5px 8px 5px 13px;
                    }

                    @media (max-width: $header-bp-m - 1) {
                        padding: 5px 5px 5px 10px;
                    }
                }
            }
        }
    }
}

// ==============================================
// Header Panel
// ==============================================

.header-account-nav {
    margin-left: auto;

    @media (min-width: $bp-screen-m) {
        display: block;
        order: 9;
    }

    .account-link {
        color: $c-white;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;

        @include lib-icon-font(
            $_icon-font-content: $icon-account,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 30px,
            $_icon-font-line-height: 30px
        );

        &:hover {
            text-decoration: none;
        }

        @media (min-width: $bp-screen-l) {
            &.logged-in {
                &:after {
                    vertical-align: bottom;
                    margin-bottom: -2px;
                }

                .account-link-label {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 18px;
                    margin-top: -2px;

                    > span {
                        display: block;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }

            .account-link-label {
                margin-right: 3px;
            }
        }

        @media (min-width: $bp-screen-m) {
            &:before {
                margin-right: 5px;
            }
        }

        @media (max-width: $bp-screen-l - 1px) {
            &:before {
                margin-right: 0;
            }

            .account-link-label,
            .account-link-label.user {
                display: none;
            }
        }

        @media (max-width: $bp-screen-m - 1px) {
            &:after {
                display: none !important;
            }
        }
    }
}

// ==============================================
// Header Links - Quick Lists
// ==============================================

@media (min-width: $bp-screen-m) {
    .header-links_quicklists {
        @include lib-icon-font(
            $_icon-font-content: $icon-quicklists,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 20px
        );

        &::before {
            margin-top: -5px;
        }
    }
}


// ==============================================
// Header Links - Quotes
// ==============================================

@media (min-width: $bp-screen-m) {
    .header-links_quotes {
        @include lib-icon-font(
            $_icon-font-content: $icon-quotes,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 20px
        );

        &::before {
            margin-top: -5px;
        }
    }
}

.header-search {
    order: 5;
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 20px;

    @media (max-width: $bp-screen-m - 1px) {
        flex: 1 1 100%;
        margin-top: 10px;
        margin-right: 0;
        background: $c-white;
        border-radius: 3px;
    }
}
