/**
* Baker Distributing
*/

.pagebuilder-icon {
    text-decoration: none;
}

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
    display: inline-block;
    font-family: "Material Icons";
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    line-height: 22px;
    speak: none;
    color: rgb(31, 126, 12);
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    font-weight: normal;

}

.pagebuilder-icon-down:before {
    content: $icon-arrow-down-thin;
}

.pagebuilder-icon-next:before {
    content: $icon-arrow-right-thin;
}

.pagebuilder-icon-prev:before {
    content: $icon-arrow-left-thin;
}

.pagebuilder-icon-up:before {
    content: $icon-arrow-up-thin;
}
