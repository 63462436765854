//
//  Email variables
//  _____________________________________________

//  Structure
$email-body__width               : 600px !default;
$email-body__padding             : $space-base !default;

//  Content
$email-content__padding__base    : $space-base !default;
$email-content__padding__s       : $space-xs !default;
$email-content__padding__m       : $space-l !default;
$email-content__padding__l       : $space-xxl !default;
$email-content__padding__xl      : $space-xxl !default;

//  Colors
$email__background-color         : $c-white !default;
$email-content__background-color : $c-white !default;

// Spacing
$indent__base: 2rem; // 20px
$indent__xl: $indent__base * 2; // 40px
$indent__l: $indent__base * 1.5; // 30px
$indent__m: $indent__base * 1.25; // 25px
$indent__s: $indent__base / 2; // 10px
$indent__xs: $indent__base / 4; // 5px

// Table
$email-table-width: 600px;

// Font
$font-weight__light: 300;
$font-weight__regular: 400;
$font-weight__heavier: 500;
$font-weight__semibold: 600;
$font-weight__bold: 700;

// Border
$border-width--base: 1px;
$border-color__base: #CCCCCC;
