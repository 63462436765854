/**
* Baker Distributing
*/

div[data-content-type='text'] {
    word-wrap: break-word;
}

div[data-content-type='text'] ul {
    list-style: initial;
}

div[data-content-type='text'] ol {
    list-style: decimal;
}
