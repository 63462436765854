/**
* Baker Distributing
*/

.pagebuilder-column {
    box-sizing: border-box;
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $bp-screen-m) {
    .pagebuilder-column {
        background-attachment: scroll !important;
        flex-basis: 100%;
    }
}
