/**
* Baker Distributing
*/

// ==============================================
// AHRI Matchups
// ==============================================

.filterHide {
    display: none;
}

.ahriTable table > thead {
    background: $c-lightestgray;
}

.ahriTable_infoContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.ahri_results-qty {
    padding-top: $space-xxs;
    flex: 1 0 auto;
}

// ==============================================
// AHRI Matches - Column configuration
// ==============================================

.ahri_toggle-columns-dropdown .dropdown-content {
    width: auto;
}

.ahri_toggle-columns-dropdown.active .dropdown-content {
    display: flex !important;
    padding: 0 !important;
}

.ahri_toggle-columns-dropdown > .dropdown-content > div {
    margin: $space-base $space-l;
    white-space: nowrap;
}

.ahri_toggle-columns-dropdown .dropdown-trigger::after {
    margin-top: -12px;
}

.ahri_toggle-columns-dropdown .dropdown-trigger > i {
    padding-right: 8px;
}

// ==============================================
// AHRI Matches table
// ==============================================

.ahri-table.table--border {
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
}

.ahri-table.table-fixed-column > thead > tr > th:first-child {
    height: 83px;
    background: $c-lightestgray !important;
}

.ahri-table_thead-helper {
    position: fixed !important;
    border: 0 !important;
}

.ahri-table.table-fixed-column > thead > tr > th:first-child,
.ahri-table.table-fixed-column  > tbody > tr > td:first-child {
    width: 92px;
}

.ahri-table.table-fixed-column  > tbody > tr > td.ahri-table_row-1lines {
    height: 42px;
}

.ahri-table.table-fixed-column  > tbody > tr > td.ahri-table_row-2lines {
    height: 64px;
    padding-top: 21px;
}

.ahri-table.table-fixed-column > thead > tr > th:first-child {
    padding-top: 30px;
}

.ahri-table.table-fixed-column > thead > tr:first-child > th:nth-child(2),
.ahri-table.table-fixed-column  > tbody > tr > td:nth-child(2) {
    border-left: none;
}

.ahri-table_wrapper > .table-fixed-column-inner {
    margin-left: 92px !important;
}

.ahri-table .table--border {
    border: none !important;
}

.ahri-table td {
    white-space: nowrap;
}


// ==============================================
// AHRI Matches - Filters
// ==============================================

.ahri_filter-min,
.ahri_filter-max {
    width: 70px;
}

.ahri-filters_icon {
    vertical-align: bottom;
    margin-right: $space-xs
}

.ahri_filters-dropdown {
    margin-right: $space-xs;

    & > a,
    & > a:hover {
        color: $c-text;
    }
}

.filter-stock-only {
    > label > span {
        padding: $space-xxs $space-s;
        background: $c-note-warn-background;
        border: 1px solid $c-note-warn-border;
        font-weight: $font-weight-semibold;
    }

    > span {
        font-weight: $font-weight-normal;
        font-style: italic;
    }
}

@media (min-width: $bp-screen-m-s) {
    .ahri-filters {
        display: flex;
        align-items: baseline;
        column-gap: 40px;

        > .field {
            flex: 1;
            margin-bottom: 0;

            &.field-filter-system-type {
                max-width: $form-elem-width-xs;
            }
        }

        .filter-current {
            align-self: flex-end;
        }
    }
}

@media (max-width: $bp-screen-m-s - 1px) {
    .ahri-filters {
        margin-top: 32px;
    }

    .ahri_filter-min,
    .ahri_filter-max {
        width: 100%;
    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .ahri_filters-dropdown {
        text-align: right;
    }

    .ahri_filters-dropdown .dropdown-content {
        text-align: left;
    }

    .ahri_filters-dropdown .dropdown-content--right {
        right: -90px;
    }
}
