/**
* Baker Distributing
*/

// ==============================================
// 404 Page
// ==============================================

body.cms-no-route {
    .page-header {
        margin-bottom: 0;
    }

    .page-main {
        padding: 0;
    }

    .menu-top {
        display: none;
    }
}

.page-404-container {
    text-align: center;
    margin-bottom: 60px;

    @media (max-width: $bp-screen-m - 1px) {
        margin-bottom: 40px;
    }

    .page-404-banner {
        background: rgb(200,16,46);
        background: linear-gradient(180deg, rgba(200,16,46,1) 0%, rgba(255,255,255,1) 65%);

        @media (min-width: $bp-screen-m) {
            padding: 20px 20px 40px;
        }

        @media (max-width: $bp-screen-m - 1px) {
            padding: 15px;
        }

        .img-wrap {
            img {
                display: inline-block;
                vertical-align: top;

                @media (min-width: $bp-screen-m) {
                    &.mobile-img {
                        display: none;
                    }
                }

                @media (max-width: $bp-screen-m - 1px) {
                    &.desktop-img {
                        display: none;
                    }
                }
            }

            @media (max-width: $bp-screen-m - 1px) {
                margin-bottom: 10px;
            }
        }

        .text-wrap {
            @media (min-width: $bp-screen-m) {
                max-width: 960px;
                margin: 0 auto;
            }

            h1 {
                font-size: 48px;
                font-weight: 500;
                margin-bottom: 10px;

                @media (max-width: $bp-screen-m - 1px) {
                    font-size: 36px;
                }
            }

            h2 {
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 20px;

                @media (max-width: $bp-screen-m - 1px) {
                    font-size: 20px;
                }
            }

            p {
                margin-bottom: 0;

                @media (min-width: $bp-screen-m) {
                    font-size: 16px;
                }
            }
        }
    }

    .page-404-extra {
        @media (min-width: $bp-screen-m) {
            max-width: 960px;
            margin: 0 auto;
            padding: 0 20px;
        }

        @media (max-width: $bp-screen-m - 1px) {
            padding: 0 15px;
        }

        .page-404-search {
            max-width: 600px;
            margin: 0 auto 20px;
        }

        .page-404-links {
            @media (min-width: $bp-screen-m) {
                display: flex;
                justify-content: center;
            }

            @media (max-width: $bp-screen-m - 1px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                row-gap: 10px;
                column-gap: 15px;
            }

            li {
                @media (min-width: $bp-screen-m) {
                    &:not(:last-child):after {
                        content: "|";
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 20px;
                    }
                }

                @media (max-width: $bp-screen-xs - 1px) {
                    &:first-child {
                        grid-column: 1 / span 2;
                        margin-bottom: 10px;

                        a {
                            display: block;
                            background: #fff;
                            padding: 10px;
                            border-radius: 2px;
                            border: 1px solid #136cea;
                        }
                    }
                }
            }
        }
    }
}
