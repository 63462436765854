/**
* Baker Distributing
*/


// ==============================================
// Cards
// ==============================================

.cards {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space-l;
    margin-left: (-1 * $space-l);
}

.cards_item {
    display: flex;
    flex-direction: column;
    margin-left: $space-l;
}


.cards .cards_item {
    flex: 400px 0 1;
}


// ----------------------------------------------
// Modifier: Size XS

.cards--xs .cards_item {
    flex: 280px 0 1;
}


// ----------------------------------------------
// Modifier: Size S

.cards--s .cards_item {
    flex: 300px 0 1;
}


// ----------------------------------------------
// Modifier: Size L

.cards--l .cards_item {
    flex: 500px 0 1;
}
