/**
* Baker Distributing
*/


// ==============================================
// Login page
// ==============================================

@media (min-width: $bp-screen-m) {

    .login-grid {
        display: flex;
    }

    .login-grid > .col-1 {
        order: 1;
        width: 40%;
        padding-right: $space-xl;
    }

    .login-grid > .col-2 {
        order: 2;
        width: 60%;
        padding-left: $space-xl;
    }

}