/**
* Baker Distributing
*/

// ==============================================
// Multiinput
// ==============================================


.multiinput {
    //height: 45px;
    border: 0;
    border-radius: 0;
    background: transparent;
    background-origin: content-box;
    background-position: right 10px; //right -5px center;
    background-repeat: no-repeat;
    background-clip: padding-box;
    outline: 0;
    appearance: none;
    color: $c-text;
    vertical-align: baseline;
    transition: border ease-in-out 180ms;
    display: block;
    margin: 0 0 30px 0;
    border-bottom: 1px solid #7c7a7a;

    &.blue {
        border-bottom: 1px solid $c-input-border-focus;
        .multiinput-label {
            color: $c-input-border-focus;
        }
    }
}

.multiinput--disabled {
    background: $gray-50;
}

.multiinput-textarea {
    border: none;
    min-height: 38px;
    display: inline-block;
    flex-grow: 0.9;
    text-overflow: ellipsis;
}

.multiinput-selection {
    display: flex;
    position: relative;
    min-height: 44px;
    height: auto;
    padding: 7px 0 0 0;
    width: 97%;
    flex-flow: row wrap;
    border: none;

    .multiinput-ellipsis {
        position: absolute;
        top: 15px;
        right: 28px;
        left: auto;
        font-size: 18px;
        cursor: default;
    }
}

label.multiinput-label {
    color: $c-label;
    font-family: $font-family-sans;
    font-size: $font-size-s;
    font-weight: $font-weight-semibold;
    font-style: normal;
    letter-spacing: $letter-spacing;
}

.multiinput-optionlist{
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: solid $c-border;
    border-width: 0 1px 1px 1px;
    background: #fff;
    z-index: 9999!important;
    transition: 180ms;
    opacity: 0;
    visibility: hidden;
    max-height: 206px;
    overflow-y: auto;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.multiinput-option {
    padding: 10px 15px;
    cursor: pointer;

    &.active {
        background: #e8e8e8;
    }
}

.multiinput-option-checkbox-container {
    float: right;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark:before {
        content: $icon-checkbox-outline;
        display: inline-block;
        top: -2px;
        left: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        border-radius: $radius-base;
        box-shadow: none;
        -moz-osx-font-smoothing: grayscale;
        font-size: $checkbox-size;
        font-family: $font-family-icon;
        color: $gray-500;
        line-height: 18px;
        vertical-align: top;
     }

    input:checked ~ .checkmark:before {
        content: $icon-checkbox;
        color: $blue-600;
    }
}

.multiinput-selected {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 4px;
    background: #e8e8e8;
    border-radius: 30px;
    min-width: 72.5px;
    cursor: default;

    transition: background-color 200ms linear;
    .multiinput-selected-text {
        max-width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: default;
    }
    .multiinput-selected-delete {
        cursor: pointer;
        margin-left: 10px;
    }
    svg {
        width: 1em;
        height: 1em;
        padding-top: 4px;
    }
}

.multiinput-selected:hover {
    background: #c5c5c5
}

.multiinput-placeholder {
    padding-top: 5px;
}

.multiinput-arrow {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    background-origin: content-box;
    background-repeat: no-repeat;
    background-clip: padding-box;
    position: absolute;
    right: 0;
    bottom: 12px;
}

.multiinput-hint {
    position: absolute;
    color: $c-label;
}


// ==============================================
// Modifier: Size
// ==============================================

.multiinput--s {
    max-width: $form-elem-width-s;
}

.multiinput--xs {
    max-width: $form-elem-width-xs;
}

@media screen and (max-width: 480px) {
    .multiinput {
        margin: 0 0 40px 0;
    }
}
