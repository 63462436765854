/**
* Baker Distributing
*/

// ==============================================
// Vars
// ==============================================

$bp-parts-table: 1400px;

// ==============================================
// Parts Table
// ==============================================

@media (max-width: $bp-screen-s - 1px) {
    .parts {
        margin-left: -$space-base;
        margin-right: -$space-base;

        .parts-no_parts {
            margin-left: $space-base;
            margin-right: $space-base;
        }
    }
}

.parts-table {
    @media (max-width: 1399px) {
        display: block;
    }

    thead {
        @media (max-width: 1399px) {
            display: none;
        }

        tr th {
            border-bottom: 1px solid $c-border;
        }
    }

    > tbody {
        @media (min-width: 1160px) and (max-width: 1399px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            > tr {
                border-right: 1px solid $c-border;
            }

            > tr:nth-child(3n+1) {
                border-left: 1px solid $c-border;
            }

            > tr:nth-child(-n+3) {
                border-top: 1px solid $c-border;
            }
        }

        @media (min-width: $bp-screen-s) and (max-width: 1159px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            > tr {
                border-right: 1px solid $c-border;
            }

            > tr:nth-child(odd) {
                border-left: 1px solid $c-border;
            }

            > tr:nth-child(-n+2) {
                border-top: 1px solid $c-border;
            }
        }

        @media (max-width: $bp-screen-s - 1px) {
            display: block;

            > tr:first-child {
                border-top: 1px solid $c-border;
            }
        }

        > tr {
            &:hover {
                background: $c-table-hover;
            }

            @media (max-width: 1399px) {
                display: block;
                border-bottom: 1px solid $c-border;
                padding: 10px;

                > td {
                    display: block;
                    border: none;
                }
            }
        }
    }

    @media (min-width: $bp-parts-table) {
        .mobile-label {
            display: none;
        }
    }

    // Columns
    // =======================

    .parts_product-image {
        @media (min-width: $bp-parts-table) {
            width: 70px;
            min-width: 70px;
        }

        @media (max-width: 1399px) {
            float: left;
            margin-right: 5px;
        }
    }

    .parts_product-details {
        white-space: normal !important;
        min-width: 184px;

        @media (max-width: 1399px) {
            min-height: 70px;
        }

        .parts_product-name {
            overflow: hidden;
        }
    }

    .parts_product-supersedes {
        white-space: nowrap;

        @media (max-width: 1399px) {
            &.empty {
                display: none;
            }
        }
    }

    .parts_product-inventory {
        .inventory-wrapper--compact {
            min-height: initial;
        }

        .inventory-wrapper .inventory {
            vertical-align: middle;
        }

        .inventory-unavailable_content {
            white-space: nowrap
        }

        .inventory-unavailable_heading {
            line-height: 18px;
        }
    }

    .parts_product-actions {
        > .price-box {
            margin-bottom: $space-xxs;
            text-align: right;

            @media (max-width: 1399px) {
                text-align: left;
                margin-bottom: $space-s;
            }
        }

        .price-message {
            white-space: nowrap;
        }

        .fieldset {
            display: flex;
            flex-wrap: nowrap;
            min-width: 190px;
            column-gap: 10px;

            @media (min-width: $bp-parts-table) {
                justify-content: flex-end;
                text-align: right;
            }

            > .field:not(.choice) > .control {
                float: none;
                width: 100%;
            }

            .field {
                margin: 0;
                flex: 0 1 50px;
            }

            .control {
                margin: 0;
            }

            .actions {
                margin: 0;

                .action.tocart {
                    font-size: 12px;
                }
            }
        }
    }
}
