/**
* Baker Distributing
*/


// ==============================================
// Footer - General
// ==============================================

.page-footer {
    margin-top: auto;
}

// ==============================================
// Footer - Top
// ==============================================

.footer-top {
    position: relative;
    padding: 10px 20px;
    background: $gray-100;
    font-size: $font-size-l;
    border-top: 1px solid $c-border;

    @media (max-width: $bp-screen-m - 1px) {
        font-size: $font-size-s;
    }

    .footer-contact {
        max-width: $bp-screen-l;
        margin: 0 auto;
        padding-right: 60px;

        > span {
            display: inline-block;
            vertical-align: top;

            @media (max-width: $bp-screen-s - 1px) {
                display: block;

                &:first-of-type {
                    margin-bottom: 5px;
                }
            }

            &.footer-contact_separator {
                margin: 0 $space-xs;

                @media (max-width: $bp-screen-s - 1px) {
                    display: none;
                }
            }
        }
    }
}

// ==============================================
// Footer - Back Top
// ==============================================

.back-top {
    display: inline-block;
    position: absolute;
    right: $space-l;
    bottom: 0;
    width: 48px;
    height: 48px;
    margin-bottom: (50px / -2);
    background: $c-white;
    border-radius: 50px;
    box-shadow: 0 1px 2px black(0.15);
    text-decoration: none;
    cursor: pointer;
    transition: $transition-all;
    z-index: $z-back-top;

    &:hover {
        transform: scale(1.1);
    }

    @include lib-icon-font(
        $_icon-font-content: $icon-up,
        $_icon-font-color:   $gray-700
    );

    &::before {
        padding: 15px 13px;
    }
}


// ==============================================
// Footer - Content
// ==============================================

.footer.content {
    position: relative;
    padding-top: $gap;
    padding-bottom: $gap;
    border-top: 1px solid $c-border;
    background: #f9f9f9;
}

// ==============================================
// Footer - Nav
// ==============================================

.footer-nav {
    max-width: $bp-screen-l;
    margin: 0 auto;
    padding-bottom: $gap;
    border-bottom: 1px solid $c-border;

    @media (min-width: $bp-screen-m) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media (max-width: $bp-screen-m - 1px) {
        display: grid;
        column-gap: $gap;
    }

    @media (min-width: $bp-screen-s) and (max-width: $bp-screen-m - 1px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: $bp-screen-s - 1px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: $space-base;
    }

    .footer-nav_item {
        .footer-nav_heading a {
            color: $c-text;
            font-weight: $font-weight-bold;
        }

        .footer-nav_link {
            display: block;
            margin-bottom: 0.5em;
            color: $c-text;
        }
    }
}

// ==============================================
// Footer - Socials
// ==============================================

.footer-nav_item--social {
    @media (max-width: $bp-screen-m - 1px) {
        grid-column: 1 / -1;
    }
}

.social-link {
    text-align: center;
    margin-bottom: $gap;

    @media (max-width: $bp-screen-m - 1px) {
        margin: $space-base auto $gap;
    }

    @media (min-width: $bp-screen-s) and (max-width: $bp-screen-m - 1px) {
        margin-top: $gap;
    }

    > a {
        display: inline-block;
        vertical-align: top;
        margin-right: $space-base;

        &:last-of-type {
            margin-right: 0;
        }

        svg {
            display: block;
        }
    }

    .social-link_item {
        .social-link_background {
            fill-rule: evenodd;
            fill: $gray-700;
            transition: $transition-all;
        }

        &:hover .social-link_background {
            fill: $gray-900;
            transition: $transition-all;
        }

        .social-link_icon {
            fill: $c-white;
            fill-rule: evenodd;
        }
    }
}

// ==============================================
// Footer - Apps
// ==============================================

.app-link {
    text-align: center;

    > a {
        display: block;

        @media (min-width: $bp-screen-m) {
            margin-bottom: $gap;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @media (max-width: $bp-screen-m - 1px) {
            display: inline-block;
            vertical-align: middle;
            margin-right: $gap;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .app-link_item {
        width: 120px;
        display: inline-block;
        vertical-align: top;

        .app-link_color {
            fill: $gray-600;
            fill-rule: evenodd;
            transition: $transition-all;
        }

        &:hover .app-link_color {
            fill: $gray-900;
            transition: $transition-all;
        }
    }
}

// ==============================================
// Footer - Logo
// ==============================================

#footer-logo {
    margin: 0 auto;
}

.footer-logo {
    padding: $gap 0;
}

.footer-logo_baker {
    width: 150px;
}

.footer-logo_baker-fcs,
.footer-logo_baker,
.footer-logo_ice-design {
    margin: 0 auto;
    height: auto;
}

.footer-logo_ice-design,
.footer-logo_baker-fcs {
    width: 200px;
}
