/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

$dashboard-grid-gap: 40px;
$dashboard-grid-col-2-width: 280px;


// ==============================================
// Dashboard Accounts
// ==============================================

.dashboard-section_accounts {
    margin-bottom: $space-xxl;
}

.dashboard-accounts {
    display: grid;
    row-gap: $space-base;
    column-gap: $space-base;
    max-height: 431px;
    overflow-y: auto;
    padding-right: $space-base;

    @media (min-width: $bp-screen-xxxl) {
        grid-template-columns: repeat(8, 1fr);
    }

    @media (min-width: $bp-screen-xl) and (max-width: $bp-screen-xxxl - 1px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media (min-width: 1450px) and (max-width: $bp-screen-xl - 1px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: $bp-screen-l) and (max-width: 1450px - 1px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1140px) and (max-width: 1450px - 1px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1000px) and (max-width: 1140px - 1px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $bp-screen-m) and (max-width: 1000px - 1px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 760px) and (max-width: $bp-screen-m - 1px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $bp-screen-s) and (max-width: 760px - 1px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $bp-screen-s - 1px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.dashboard-accounts_item {
    padding: $space-s;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-white;
}

.dashboard-accounts_item p {
    margin-bottom: 10px;
}

// ==============================================
// Dashboard BakerPay
// ==============================================

.dashboard-section_bakerpay {
    position: relative;
    margin-bottom: $space-xxl;
}

.dashboard-bakerpay {
    padding: 26px $space-base;
    transition: $transition-all;
    box-shadow: $box-shadow-base;
}

.dashboard-bakerpay img {
    margin: 0 auto;
}

.dashboard-section_bakerpay a {
    color: $c-text;
}

.dashboard-section_bakerpay a:hover {
    text-decoration: none;
}

a:hover .dashboard-bakerpay {
    box-shadow: $box-shadow-hover;
}

.dashboard-bakerpay--flag {
    position: absolute;
    right: 0;
    top: 0;
}

@media (min-width: 1000px) {
    .col-wrap .dashboard-section_bakerpay {
        margin-top: 39px;
    }
}

// ==============================================
// Dashboard Account Profile
// ==============================================

.dashboard-section_accountprofile {
    margin-bottom: $space-xxl;
}

.dashboard-accountprofile {
    display: grid;
    row-gap: $space-base;
    column-gap: $space-base;
    max-height: 446px;
    overflow-y: auto;
    padding-right: $space-base;

    @media (min-width: $bp-screen-xxxl) {
        grid-template-columns: repeat(8, 1fr);
    }

    @media (min-width: $bp-screen-xl) and (max-width: $bp-screen-xxxl - 1px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media (min-width: 1450px) and (max-width: $bp-screen-xl - 1px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: $bp-screen-l) and (max-width: 1450px - 1px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1140px) and (max-width: 1450px - 1px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1000px) and (max-width: 1140px - 1px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $bp-screen-m) and (max-width: 1000px - 1px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 760px) and (max-width: $bp-screen-m - 1px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $bp-screen-s) and (max-width: 760px - 1px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $bp-screen-s - 1px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.dashboard-accountprofile_item {
    padding: $space-s;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-white;
    text-align: center;
}

.dashboard-accountprofile_item p {
    margin-bottom: 10px;
    text-align: center;
}
.dashboard-accountprofile_item svg {
    margin-bottom: 10px;
}

// ==============================================
// Dashboard Grid
// ==============================================

@media (min-width: 1000px) {

    .dashboard-grid {
        overflow: hidden;
    }

    .dashboard-grid .col-wrap {
        &:after {
            @include clearfix();
        }
    }

    .dashboard-grid > .col-1,
    .dashboard-grid .col-wrap > .col-1 {
        float: left;
        width: calc(100% - #{($dashboard-grid-gap + $dashboard-grid-col-2-width)});
    }

    .dashboard-grid > .col-2,
    .dashboard-grid .col-wrap > .col-2 {
        float: right;
        width: ($dashboard-grid-gap + $dashboard-grid-col-2-width);
        padding-left: $dashboard-grid-gap;
    }

}


// ==============================================
// Dashboard Section
// ==============================================

.dashboard-section {
    margin-bottom: $space-xxl;
}

// ==============================================
// Dashboard Quick Lists
// ==============================================

//.dashboard-quick-lists {}

.dashboard-quick-lists_item {
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}
