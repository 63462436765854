// ==============================================
// Algolia Search Bar
// ==============================================

// ----------------------------------------------
.block-search,
#stocking-list-typeahead {
    .algolia-search-bar {
        input {
            border: none;
            height: 33px;
            border-radius: 2px 0 0 2px;
            width: 100%;
        }

        span {
            height: 33px;

            button {
                border-radius: 0 2px 2px 0;
            }

            svg {
                margin-top: -2px;
            }
        }
        #searchSuggestions .m-0.cursor-pointer {
            background-color: white;
        }
        #searchSuggestions .m-0.cursor-pointer:hover {
            background-color: $blue-50 !important;
        }
    }

    .algolia-autosuggest {
        border: 1px solid #d3d3d3;

        .list {
            margin-left: 0;
        }

        .product-image {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }

        #products-collection > div:hover > div {
            background-color: $blue-50 !important;
        }

        .product-card__root {
            width: 100%;
        }
    }
}

// Add styling for search bar when not on the category, brand, or search results pages
body:not(.page-products) {
    .block-search,
    #stocking-list-typeahead {
        .algolia-search-bar {
            // search button
            .p-1\.5 { padding: 0.375rem; }
            .h-full { height: 100%; }
            .float-right { float: right; }
            .w-6 { width: 1.5rem; }
            .h-6 { height: 1.5rem; }

            // autosuggest dropdown
            .max-h-96 { max-height: 24rem; }
            .overflow-auto { overflow: auto; }
            .w-full { width: 100%; }
            .bg-white { --tw-bg-opacity: 1; background-color: rgba(255,255,255,var(--tw-bg-opacity)); }
            .relative { position: relative; }
            .absolute { position: absolute; }
            .z-10 { z-index: 10; }
            .py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
            .py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
            .py-3 { padding-top: 0.75rem; padding-bottom: 0.75rem; }
            .px-4 { padding-left: 1rem; padding-right: 1rem; }
            .border { border-width: 1px; }
            .border-b { border-bottom-width: 1px; }
            .border-gray-200 { --tw-border-opacity: 1; border-color: rgba(229,231,235,var(--tw-border-opacity)); }
            .flex { display: flex; }
            .flex-row { flex-direction: row; }
            .list { list-style: disc outside none; }
            .grid-cols-1 { grid-template-columns: repeat(1, minmax(0,1fr)); }
            .items-center { align-items: center; }
            .object-contain { object-fit: contain; }
            .mx-auto { margin-left: auto; margin-right: auto; }
            .truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
            .text-sm { font-size: .875rem; line-height: 1.25rem; }
            .text-left { text-align: left; }
            .bg-none { background-image: none; }
            .bg-transparent { background-color: transparent; }
            .border-none { border-style: none; }

            @media all and (min-width: 768px) {
                .md\:flex { display: flex; }
                .md\:flex-row { flex-direction: row; }
            }

            // DMI search dropdown
            .text-gray-400 { --tw-text-opacity: 1; color: rgba(156, 163, 175, var(--tw-text-opacity)); }
            .pb-3 { padding-bottom: 0.75rem; }
            .font-bold { font-weight: 700; }
            .leading-snug { line-height: 1.375; }
            .uppercase { text-transform: uppercase; }
            .mb-1\.5 { margin-bottom: 0.375rem; }
            .cursor-pointer { cursor: pointer; }
        }
    }
}

#stocking-list-typeahead {
    .algolia-search-bar {
        border-bottom: 1px solid $gray-600;
    }
}
