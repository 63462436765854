/**
* Baker Distributing
*/

// ==============================================
// Single Order History > Life Cycle Header
// ==============================================
#order-life-cycle-header {
    padding: 0;
    margin: 0;
}

#order-life-cycle-header h2 {
    margin-bottom: 0;
}
    // ==============================================
// Single Order History > Life Cycle Progress Bar
// ==============================================
table.table-life-cycle-status {
    margin-bottom: 0;
}

table.table-life-cycle-status th.col {
    padding: 10px 2px 0px;
    font-size: 12px;
    text-transform: uppercase;
}
table.table-life-cycle-status th {
    color: #cccccc;
}

table.table-life-cycle-status th.complete {
    color: #3A3A3A;
}
