/**
* Baker Distributing
*/

.inventory-wrapper--compact {
    min-height: 80px
}

.inventory {
    display: inline-block;
}

.inventory-unavailable {
    background: $c-lightestgray;
    color: $c-inventory-unavailable;
    padding: $space-xs $space-base;
    border: 1px solid $c-border-light;
    border-radius: $radius-base;
}

.inventory-unavailable_heading {
    font-weight: $font-weight-semibold;
}

.inventory_check a {
    white-space: nowrap;
}

.inventory-status {
    border-spacing: 6px 2px;
    border-collapse: separate;
    margin-left: -6px;
}

.inventory-status td {
    vertical-align: bottom;
    white-space: nowrap;
    border: none !important;
}

.inventory-status td:not(.inventory-status_available, .inventory-status_count) {
    padding: 0;
}

.inventory-status_available,
.inventory-status_unavailable,
.inventory-status_count {
    text-align: center;
    background: $c-inventory-available;
    border-radius: 2px;
    color: white;
    min-width: 30px;
}

.inventory-status_available {
    padding: 2px 4px 0 4px !important;
    line-height: 14px;
}

.inventory-status_unavailable {
    background: $gray-100;
    color: $c-darkgray;
}

.inventory-status_count {
    font-size:$font-size-xs;
    padding: $space-xxxs $space-xxs !important;
}

.inventory-status_available .material-icons {
    font-size: 18px;
}

// ==============================================
// Modifier: Compact
// ==============================================

.inventory-compact {
    width: 100%;
    overflow: hidden;
}
.inventory-compact .inventory-status {
    margin-bottom: $space-xxs;
}

// ==============================================
// Modifier: Full
// ==============================================

.inventory-full {
    border: 1px solid $c-border-light;
    border-radius: $radius-base;
}

.inventory-full_instore {
    background: $c-lightestgray;
    padding: $space-s $space-base;
}

.inventory-full_instore-label {
    color: $c-inventory-available;
}

.inventory_check-status {
    color: $c-inventory-available;
}

.inventory-full_status-available {
    color: $c-inventory-available;
    font-weight: $font-weight-semibold;
}

.inventory-full_status-unavailable {
    color: $c-inventory-unavailable;
    font-weight: $font-weight-semibold;
}

.inventory-full_instore-name {
    font-weight: $font-weight-semibold;
    color: $gray-900;

    @include lib-icon-font(
        $_icon-font-content     : $icon-location,
        $_icon-font-size        : 16px,
        $_icon-font-line-height : 16px,
        $_icon-font-position    : before,
        $_icon-font-display     : block,
        $_icon-font-color       : $gray-900
    );
    &:before {
        margin: -4px 0 0 -3px;
    }
}

.inventory-full_nearby {
    padding: $space-s $space-base;
}

.inventory-full_nearby-label {
    color: $c-text-muted;
    margin-bottom: $space-xxs;
    font-size: $font-size-xxs;
    font-weight: $font-weight-semibold;
}

.inventory-full .inventory_check {
    margin: 0 $space-base $space-base $space-base;
}

.inventory-full .inventory_check--additional {
    margin-top: $space-base;
}
