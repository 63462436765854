/*
!**
* Baker Distributing
*/


// ==============================================
// Products Carousel
// ==============================================

@-moz-document url-prefix() {
    .products-carousel {
        max-width: calc(100vw - 5 * 16px)
    }
}

.products-carousel > div {
    margin-left: -12px;
    margin-right: -12px;
    z-index: 1;
}

.products-carousel > h2 {
    font-size: $font-size-xxxl;
    margin-right: 120px;
}

.products-carousel .slick-track {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

// ==============================================
// Products Carousel - Dots
// ==============================================

.products-carousel .slick-dots li.slick-active button {
    border: none !important;
    background: $c-primary !important;
}

// ==============================================
// Products Carousel - Arrows
// ==============================================

.products-carousel_next,
.products-carousel_prev {
    position: absolute;
    top: -58px;
    z-index: $z-index-10;
}

.products-carousel_next {
    right: 12px;
}

.products-carousel_prev {
    right: 70px;
}

// ==============================================
// Products Carousel - Item
// ==============================================

.products-carousel_item {
    padding: 0 $space-s;
}

.products-carousel_details:hover,
.products-carousel_details:hover .products-carousel_name {
    color: $c-text;
}

h3.products-carousel_name {
    font-size: $font-size-base;
    height: 40px;
    overflow: hidden;
}

.products-carousel_image {
    display: block;
    border: 1px solid $c-border-light;
    margin-bottom: $space-xs;
    padding: $space-s;
    position: relative;

    .energy-star-badge {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        img {
            width: 50px;
            height: auto;
        }
    }
}

.products-carousel_image img {
    height: auto;
    margin: 0 auto;
}

.products-carousel .tocart {
    width: 100%;
}
