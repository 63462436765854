/**
* Baker Distributing
*/


// ==============================================
// Page Title
// ==============================================

.checkout-cart-index h1.page-title {
    margin-bottom: $space-base;
}


// ==============================================
// Cart Summary
// ==============================================

.cart-summary {
    @extend .abs-adjustment-incl-excl-tax;
    background: $gray-50;
    margin-bottom: $space-l;
    padding: $space-l;
}

.cart-summary > .title {
    @include h2;
}

.cart-summary .item-options {
    margin-left: 0;
}

.cart-summary .fieldset {
    margin-top: $space-base;
}

.cart-summary .field {
    margin-bottom: $space-base;
}

.cart-summary .methods .field > .label {
    display: inline;
}

.cart-summary .estimate > .legend,
.cart-summary .estimate > .legend + br {
    display: none;
}


// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .cart-summary {
        float: right;
        position: relative;
        width: 28%;
        max-width: 400px;
    }

    .column.main .cart-summary .actions-toolbar {
        margin-left: 0;
    }

    .column.main .cart-summary .actions-toolbar > .secondary {
        float: none;
    }

}


// ==============================================
// Cart Totals
// ==============================================

.cart-totals {
    box-shadow: $box-shadow-base;
    margin: $space-base 0;
    padding: $space-base;
    border-top: 1px solid $c-border;
    background: $c-white;
    border-radius: $radius-base;
}

.cart-totals .mark {
    padding-left: 0;
    strong {
        font-weight: $font-weight-semibold;
    }
}

.cart-totals .amount {
    padding-right: 0;
    text-align: right;
    white-space: nowrap;
    font-weight: 500;

    strong {
        font-weight: $font-weight-normal;
    }
}

.cart-totals table {
    width: 100%;
}

// ==============================================
// Cart Totals - Rules
// ==============================================

.total-rules th.mark {
    padding-left: $space-xs;
}

.rule-name {
    font-weight: $font-weight-normal;
}

.rule-amount {
    font-weight: 400;
}

.cart-summary tr.cart-discount .title.-enabled:after,
.cart-summary tr.cart-discount .title.-enabled.-collapsed:after {
    width: 9px !important;
    height: 9px !important;
    margin: -1px 0 0 8px;
}


.discount-info .discount.coupon {
    display: none;
}

.discount-info {
    th, td {
        border-bottom-color: transparent;
    }
}

.discount-info th {
    min-width: 120px;
}

.discount-info .title.enabled {
     @include lib-button-icon(
             $_icon-font-size: 18px,
             $_icon-font-content: $icon-pointer-up,
             $_icon-font-position: "after",
             $_icon-font-color-hover: $c-icon-base,
             $_icon-font-margin: -1px 0 0 3px

     );
    cursor: pointer;
 }

.discount-info .title.enabled.collapsed {
    @include lib-button-icon(
            $_icon-font-size: 18px,
            $_icon-font-content: $icon-pointer-down,
            $_icon-font-position: "after",
            $_icon-font-color-hover: $c-icon-base,
            $_icon-font-margin: -1px 0 0 3px
    );
    cursor: pointer;
}

.cart-totals .grand .amount {
    padding-right: 0;
    text-align: right;

    strong {
        font-weight: $font-weight-bold;
    }
}

.cart-totals .msrp {
    margin-bottom: $space-base;
}

.cart-totals .totals-tax-summary .mark,
.cart-totals .totals-tax-summary .amount {
    border-top: 1px solid $c-border;
    border-bottom: 1px solid $c-border;
    cursor: pointer;
}

.cart-totals .totals-tax-summary .amount .price {
    @include lib-icon-font(
        $icon-down,
        $_icon-font-size      : 30px,
        $_icon-font-text-hide : true,
        $_icon-font-position  : after,
        $_icon-font-display   : block
    );
    padding-right: $space-l;
    position: relative;

    &::after {
        position: absolute;
        right: -5px;
        top: -12px;
    }
}

.cart-totals .totals-tax-summary.expanded .mark,
.cart-totals .totals-tax-summary.expanded .amount {
    border-bottom: 0;
}

.cart-totals .totals-tax-summary.expanded .amount .price {
    @include lib-icon-font-symbol(
        $_icon-font-content  : $icon-up,
        $_icon-font-position : after
    );
}

.cart-totals .totals-tax-details {
    display: none;
    border-bottom: 1px solid $c-border;

    &.shown {
        display: table-row;
    }
}

// ==============================================
//  Cart . Table Wrapper
// ==============================================

.cart.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.actions {
    vertical-align: middle;
}

.cart.table-wrapper .col.qty .control {
    margin: -12px auto;
}

.cart.table-wrapper .product-item-photo {
    padding-right: 20px;
    float: left;
    width: 100px;
}

.cart.table-wrapper .product-item-photo img {
    height: auto;
}

.cart.table-wrapper .product-item-details {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    width: auto;
}

.cart.table-wrapper .product-item-details {
    white-space: normal;
}

.cart.table-wrapper .gift-registry-name-label {
    &::after {
        content: ':';
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .cart.table-wrapper th.col {
        display: none;
    }

    .cart.table-wrapper tbody.item {
        border-top: 1px solid $c-border;
        border-bottom: 1px solid $c-border;
    }

    .cart.table-wrapper tbody .col {
        display: block;
        float: left;
        border-top: none;
        border-bottom: none;
    }

    .cart.table-wrapper tbody .col.inv{
        width: 100%;
        padding-bottom: 0px;

        .inventory_instore {
            margin-right: $space-xs;
            float: left;
        }
    }

    .cart.table-wrapper tbody .col.qty,
    .cart.table-wrapper tbody .col.subtotal {
        width: 35%;
    }

    .cart.table-wrapper tbody .col.actions {
        width: 30%;
        text-align: right;
        > * {
            margin-top: 10px;
        }
    }

    .cart.table-wrapper tbody .col.item {
        display: block;
        position: relative;
        min-height: 100px;
        width: auto;
        padding-bottom: 0;
    }

    .cart.table-wrapper tbody .col.price::before,
    .cart.table-wrapper tbody .col.subtotal::before {
        content: attr(data-th);
        display: block;
        padding-bottom: 10px;
        color: $c-label;
        @include caption;
    }

    .cart.table-wrapper .col.qty .control {
        margin: 5px auto;
    }
}

@media (min-width: $bp-screen-s) {

    .cart.table-wrapper .col.price,
    .cart.table-wrapper .col.subtotal,
    .cart.table-wrapper .col.msrp {
        text-align: right;
    }

    .cart.table-wrapper .col.qty .label {
        display: none;
    }
}



// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .cart.table-wrapper {
        overflow: inherit;
    }

    .cart.table-wrapper.item-actions td {
        text-align: left;
    }

    .cart.table-wrapper.col {
        display: block;
        width: 100%;
    }

    .cart.table-wrapper.col .item {
        @include clearfix();
    }

    .cart.table-wrapper.col.qty,
    .cart.table-wrapper.col.price,
    .cart.table-wrapper.col.subtotal,
    .cart.table-wrapper.col.msrp {
        display: block;
        float: left;
        white-space: nowrap;
        width: auto;
        text-align: left;
        padding-top: 0;

        &::before {
            font-size: $font-size-xs;
            color: $c-text-muted;
            content: attr(data-th) ':';
            display: block;
            font-weight: $font-weight-bold;
            padding-bottom: $space-xs;
        }
    }

    .cart.table-wrapper.col.price    > .price-excluding-tax ,
    .cart.table-wrapper.col.subtotal > .price-excluding-tax  {
        line-height: 38px;
    }

    .cart.table-wrapper.col.msrp {
        white-space: normal;
    }

    .cart.table-wrapperthead .col.qty,
    .cart.table-wrapperthead .col.price,
    .cart.table-wrapperthead .col.item,
    .cart.table-wrapperthead .col.subtotal {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    // Google Chrome version 44.0.2403.107 m fix
    .cart.table-wrapper .items {
        min-width: 100%;
        width: auto;
    }

    .cart.table-wrapper .item-actions .actions-toolbar {
        display: table-cell;
    }

    .cart.table-wrapper .cart-products-toolbar + .cart thead th.col {
        padding-bottom: 7px;
        padding-top: 8px;
        margin: 0;
    }

    .cart.table-wrapper .cart + .cart-products-toolbar {
        margin-top: $space-l;
    }

}


// ==============================================
// Order Items . Table Wrapper
// ==============================================

.order-items.table-wrapper .col.price,
.order-items.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.msrp {
    text-align: right;
}
.order-items.table-wrapper .col.qty {
    text-align: center;
}


// ==============================================
// Cart Container
// ==============================================

.cart-container .form-cart .continue {
    @include lib-icon-font(
        $_icon-font-content: $icon-prev,
        $_icon-font-position: before,
        $_icon-font-color  : $blue-600
    );
}

.cart-container .checkout-methods-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$space-xs;
    margin-right: -$space-xs;
}

.cart-container .checkout-methods-items > * {
    flex: 1 1 auto;
    margin: 0 $space-xs $space-s $space-xs;
}

.cart-container .checkout-methods-items *:only-child {
    width: 100%;
}


// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .cart-container .form-cart {
        @extend .abs-shopping-cart-items-mobile;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .cart-container {
        @include lib-clearfix;
    }

    .cart-container .form-cart {
        @extend .abs-shopping-cart-items-desktop;
        padding-right: $space-xxl;
    }

    .cart-container .widget {
        float: left;
    }

}


// ==============================================
// Cart Products Toolbar
// ==============================================

.cart-products-toolbar .toolbar-amount {
    margin: $space-l 0 $space-base;
    padding: 0;
    text-align: center;
}

.cart-products-toolbar .pages {
    margin: 0 0 $space-l;
    text-align: center;
}

.cart-products-toolbar .pages .items > .item {
    border-bottom: 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .cart-products-toolbar .toolbar-amount {
        line-height: 30px;
        margin: 0;
    }

    .cart-products-toolbar .pages {
        float: right;
        margin: 0 0 1px;
    }

    .cart-products-toolbar .pages .item {
        &:last-child {
            margin-right: 0;
        }
    }

}


// ==============================================
// Cart Products Toolbar Top
// ==============================================

// [todo] rewrite as modifier

.cart-products-toolbar-top {
    border-bottom: 1px solid $c-border;
}

// ==============================================
// Cart Products - Inventory
// ==============================================

.cart-container .inventory-wrapper--compact {
    min-height: initial;
}

// ==============================================
// Cross Sell
// ==============================================

.block.crosssell {
    margin-top: 70px;
}


// ==============================================
// Cart Tax Info
// ==============================================

.cart-tax-info       + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
    display: block;
}

// ==============================================
// Cart Totals - Fees
// ==============================================


.fee-breakdown th.mark {
    padding-left: $space-xs;
    font-weight: $font-weight-normal;
}

.fee-breakdown td.amount {
    padding-right: $space-xs;
    font-weight: 400;
}

.fee-breakdown--hidden {
    display: none;
}

.totals.fee .mark {
    @include lib-button-icon(
        $_icon-font-size: 18px,
        $_icon-font-content: $icon-up,
        $_icon-font-position: "after",
        $_icon-font-color: $c-icon-base,
        $_icon-font-color-hover: $c-icon-base,
        $_icon-font-color-active: $c-icon-base,
        $_icon-font-margin: -2px 0 0 3px
    );

    cursor: pointer;
}

.totals.fee--collapsed .mark {
    @include lib-button-icon(
        $_icon-font-size: 18px,
        $_icon-font-content: $icon-down,
        $_icon-font-position: "after",
        $_icon-font-color: $c-icon-base,
        $_icon-font-color-hover: $c-icon-base,
        $_icon-font-color-active: $c-icon-base,
        $_icon-font-margin: -2px 0 0 3px
    );
}
