/**
* Baker Distributing
*/

// ==============================================
// Menu Top
// ==============================================

.menu-top {
    @media (min-width: $bp-screen-m) {
        position: absolute;
        top: 100%;
        right: $layout-indent__width;

        .greet.welcome {
            display: none;
        }
    }

    @media (max-width: $bp-screen-m - 1px) {
        position: static;
        padding: 12px 16px;
        background: $c-contrast-background;
        color: $c-white;
    }
}

// ==============================================
// Account Selector
// ==============================================

// Hide account selector from specific pages
@media (min-width: $bp-screen-m) {
    body.store-locator .company-control,
    body.cms-home .company-control {
        display: none;
    }
}

.company-control {
    @media (min-width: $bp-screen-m) {
        max-width: 400px;
        margin-top: 4px;

        .block {
            display: flex;
        }
    }

    .block {
        margin-bottom: 0;
    }

    .label {
        display: none;
    }

    .control {
        display: block;
        margin-top: 0;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .menu-top .company-control {
        margin-top: 5px;

        select {
            color: $c-contrast-input-text;
            border-bottom-color: $c-contrast-input-border-bottom;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23e9e9e9' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");

            &:focus ~ label {
                color: $c-contrast-label-focus;
            }

            &:focus {
                border-bottom-color: $c-contrast-input-border-focus;
            }
        }
    }
}

// ==============================================
// Job Selector
// ==============================================

.job-selector {
    @media (min-width: $bp-screen-m) {
        margin-left: $space-base;
    }
}
