/**
* Baker Distributing
*/

div[data-content-type='slider'] {
    display: none;

    &.slick-initialized {
        display: block;
    }

    .slick-list,
    .slick-track,
    .slick-slide {
        min-height: inherit;

        > div {
            line-height: 0;
            min-height: inherit;
            overflow: hidden;
            width: 100%;
        }
    }
}
