/**
* Baker Distributing
*/

// ----------------------------------------------
// Events

.events-container > div {
    height: 1000px;
}

@media (max-width: $bp-screen-m-s - 1px) {
    .events-container > div {
        height: 920px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .events-container > div {
        height: 810px;
    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .events-container > div {
        height: 760px;
    }
}

@media (max-width: $bp-screen-xxs - 1px) {
    .events-container > div {
        height: 620px;
    }
}
