/**
* Baker Distributing
*/

// ==============================================
// One Trust - Mobile button
// ==============================================
.account-nav-before-open,
.account-nav-open,
.nav-before-open,
.nav-open {
    #ot-sdk-btn-floating.ot-floating-button {
        visibility: hidden;
        z-index: 1 !important;
        transition: none;
    }
}

// ==============================================
// Print View
// ==============================================

@media print {
    #onetrust-consent-sdk {
        display: none;
    }
}

