/**
* Baker Distributing
*/

//
//  Variables
//  ---------------------------------------------

$lac-notification-background-color: #373330;
$lac-notification-color: #fff;
$lac-notification-links-color: #fff;

//
//  Common
//  ---------------------------------------------

.lac-notification-sticky {
    position: relative;
    z-index: 999;

    .lac-notification {
        background-color: $lac-notification-background-color;
        color: $lac-notification-color;
        font-size: 16px;

        .top-container {
            padding: 15px 20px;
        }

        .lac-notification-text {
            margin-right: 20px;
        }

        .lac-notification-links {
            a {
                color: $lac-notification-links-color;
                font-size: 14px;
                line-height: 24px;
            }

            .lac-notification-close-link {
                display: inline-block;
                vertical-align: top;

                &:after {
                    font-family: "Material Icons";
                    content: "close";
                    display: inline-block;
                    vertical-align: top;
                    font-size: 18px;
                    line-height: 24px;
                    margin-left: 5px;
                }
            }
        }
    }
}

@media (min-width: $bp-screen-s) {
    .lac-notification .top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .lac-notification-sticky .lac-notification {
        font-size: 14px;

        .lac-notification-text {
            margin-right: 0;
            margin-bottom: 5px;
        }

        .lac-notification-links {
            text-align: right;
        }
    }
}
