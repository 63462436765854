/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

$checkout-sidebar-width: 300px;


// ==============================================
// Checkout Container
// ==============================================

.checkout-container {
    display: flex;
    flex-wrap: nowrap;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .checkout-container {
        flex-wrap: wrap;
    }
}

.checkout-container .no-quotes-block {
    margin: $space-l 0;
}


// ==============================================
// OPC Wrapper
// ==============================================

.opc-wrapper .step-content {
    padding: $space-l;
    border: 1px solid $c-border;
}

// ----------------------------------------------

.opc-wrapper {
    flex: 1 1 100%;
    order: 2;
}

@media (min-width: $bp-screen-m) {

    .opc-wrapper {
        flex-basis: auto;
        padding-right: $space-l;
        order: 1;
    }

}


// ==============================================
// OPC Sidebar
// ==============================================

.opc-sidebar {
    flex: 0 0 100%;
    order: 1;
    border: 1px solid $c-border;
    margin-bottom: $space-l;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .opc-sidebar {
        flex-basis: $checkout-sidebar-width;
        order: 2;
        margin-top: -152px;
        margin-right: -20px;
        margin-bottom: 0;
        background: $c-lightestgray;
        border: none;
    }

    .opc-sidebar .action-close {
        display: none;
    }

    .opc-sidebar .modal-inner-wrap {
        transform: none;
        overflow: hidden;
        background: transparent;
    }

}

.opc-sidebar .total-rules{
    th, td {
        padding-top: $space-xxs;
        padding-bottom: $space-xxs;
    }
}


// ==============================================
// Checkout Control
// ==============================================

.checkout-control {
    margin: 0 0 $space-l 0;
    border: 1px solid $c-border;
    padding: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .checkout-control .control {
        margin-bottom: 10px;
    }
}

@media (min-width: $bp-screen-s) {

    .checkout-control {
        display: inline-flex;
        width: 100%;
    }

    .checkout-control .control {
        margin-right: $space-base;
    }

    .checkout-control .job-selector {
        margin-left: 0;
    }

    .checkout-control .label {
        display: block;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .checkout-control {
        width: calc(100% - (#{$checkout-sidebar-width}));
    }

}


// ==============================================
// Checkout PO Number
// ==============================================

.ponumber-tooltip {
    position: absolute;
    right: 0;
    top: 10px;
}

.checkout-jobname {
    width: 150px;
}

.checkout-ponumber {
    width: 170px;
    padding-right: 22px;
}

.company-control, .checkout-jobname, .checkout-ponumber {
    margin-top: 0;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .checkout-ponumber, .checkout-jobname {
        margin-top: 10px;
    }

}

// Hide steps temporarily
//-----------------------------------------

.checkout-container .steps {
    display: none;
}

// Hide Apply Gift Card
//-----------------------------------------

.opc-payment-additional.giftcardaccount {
    display: none;
}


// Billing Step
//-----------------------------------------

.checkout-billing-address {
    border-bottom: 1px solid $c-border-light;
}

// ==============================================
// Totals
// ==============================================

.totals.discount .title.-enabled:after,
.totals.discount .title.-enabled.-collapsed:after {
    width: 9px !important;
    height: 9px !important;
    margin: -2px 0 0 8px;
}

.opc-totals-table .totals.fee .mark:after,
.opc-totals-table .totals.fee--collapsed .mark::after {
    margin: 1px 0 0 3px;
}
