/**
* Baker Distributing
*/

[data-content-type='row'][data-appearance='full-width'] {
    box-sizing: border-box;

    > .row-full-width-inner {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        width: 100%;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $bp-screen-m) {
    [data-content-type='row'][data-appearance='full-width'] {
        background-attachment: scroll !important;
    }
}
