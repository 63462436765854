/**
* Baker Distributing
*/

// ==============================================
// Product Mention
// ==============================================

.product-mention {
    display: flex;
    align-items: center;
    background: $c-background;
    padding: $space-xs $space-base;
    margin-bottom: $space-base;
}

.product-mention_image {
    margin-right: 16px;
}

.product-mention_image img {
    max-width: initial;
}

.product-mention_name {
    white-space: normal;
}
