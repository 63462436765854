/**
* Baker Distributing
*
* Styles for Algolia pager
*/


// ==============================================
// Vars
// ==============================================

$pager-color: $blue-700;
$pager-current-color: #ffffff;
$pager-current-bg: $blue-700;
$pager-nav-color: #929090;


// ==============================================
// Pages
// ==============================================

//.pages {}


// ==============================================
// Pages - Nav
// ==============================================

.pages nav {
    box-shadow: none;
}


// ==============================================
// Pages - Buttons
// ==============================================

//.pages .item {}

.pages nav > button {
    border: none;
    background: none;
    color: $pager-color;
    font-size: 16px;
    line-height: 32px;
    font-weight: $font-weight-normal;
    position: relative;

    // currently selected item
    &.z-10 {
        color: $pager-current-color;
        background: $pager-current-bg;
    }

    // all buttons except currently selected
    &:not(.z-10)::after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 50%;
        width: 50%;
        height: 0.1em;
        background-color: $pager-color;
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
        transform: translateX(-50%) scale(0);
        transform-origin: center;
    }

    // previous & next buttons
    &#pagination__prev-button, &#pagination__next-button {
        color: $pager-nav-color;

        // hide underline animation
        &::after {
            display: none;
        }
    }

    // hover state (not currently selected)
    &:hover:not(.z-10)::after {
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }
}

.pages nav > button > svg {
    height: 2rem;
    width: 2rem;
}
