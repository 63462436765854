/**
* Baker Distributing
*/

// ==============================================
// Payment Options
// ==============================================

$checkout-payment-method-title__padding: $indent__base;
$checkout-payment-method-title-mobile__padding: 15px;
$checkout-payment-method-content__padding__xl: 22px;
$minicart-icons-color: $c-library-gray-56;

$checkout-payment-option-title__border: $border-width__base solid $c-library-gray-80;
$checkout-payment-option-title__color: $c-link;
$checkout-payment-option-title__padding: $checkout-payment-method-title__padding;
$checkout-payment-option-title-mobile__padding: $checkout-payment-method-title-mobile__padding;

$checkout-payment-option-title-icon__font-size: 32px;
$checkout-payment-option-title-icon__line-height: 16px;
$checkout-payment-option-title-icon__margin: 0;
$checkout-payment-option-title-icon__color: $minicart-icons-color;
$checkout-payment-option-title-icon__hover__color: $c-primary;

$checkout-payment-option-content__padding__xl: $checkout-payment-method-content__padding__xl;

.checkout-payment-method {
    .payment-option {
        &._active {
            .payment-option-title {
                .action-toggle {
                    &:after {
                        content: $icon-up;
                    }
                }
            }
        }

        &._collapsible {
            .payment-option-title {
                cursor: pointer;
            }

            .payment-option-content {
                display: none;
            }
        }
    }

    .payment-option-title {
        border-top: $checkout-payment-option-title__border;
        padding: $checkout-payment-option-title__padding 0;

        .action-toggle {
            color: $checkout-payment-option-title__color;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size: $checkout-payment-option-title-icon__font-size,
                $_icon-font-line-height: $checkout-payment-option-title-icon__line-height,
                $_icon-font-color: $checkout-payment-option-title-icon__color,
                $_icon-font-color-hover: $checkout-payment-option-title-icon__hover__color,
                $_icon-font-color-active: $checkout-payment-option-title-icon__color,
                $_icon-font-margin: $checkout-payment-option-title-icon__margin,
                $_icon-font-position: after
            );
        }
    }

    .payment-option-content {
        padding: 0 0 $indent__base $checkout-payment-option-content__padding__xl;
    }

    .payment-option-inner {
        margin: 0 0 $indent__base;
    }
}
