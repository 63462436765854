/**
* Baker Distributing
* It needs javascript adaptation. See implemented on Baker_Parts/Watsco_Parts module
*/

// ==============================================
// Vars
// ==============================================

$table-fixed-column-first-column-width-base: 116px !default;


// ==============================================
// Fixed Column Table
// ==============================================


// ==============================================
// Wrapper
// ==============================================

.table-fixed-column-outter {
    position: relative;
    display: grid;
}

.table-fixed-column-outter--has-scroll {
    border-right: 16px solid $c-border-light;
}

.table-fixed-column-inner {
    overflow-x: scroll;
    overflow-y: visible;
    margin-left: $table-fixed-column-first-column-width-base;
}

.table-fixed-column-outter--has-scroll > .table-fixed-column-inner:after {
    @include triangle($direction: right, $color: $c-icon-base);
    right: -10px;
    top: 14px;
}

// ==============================================
// Table
// ==============================================


table.table-fixed-column {
    table-layout: auto;
    width: 100%;
    border-collapse: unset;
    margin-bottom: 0;
}

.table-fixed-column > tbody > tr > td {
    border-bottom: none;
}

.table-fixed-column > thead > tr > th:first-child,
.table-fixed-column  > tbody > tr > td:first-child {
    position: absolute;
    left: 0;
    width: $table-fixed-column-first-column-width-base;
    background: $c-white;
}

.table-fixed-column > tbody > tr:last-child > td {
    border-bottom: 1px solid $c-border-light;
}

.table-fixed-column > tbody > tr > td:first-child {
    border-right: 1px solid $c-border-light;
    border-left: 1px solid $c-border-light;
}

.table-fixed-column > tbody > tr > td:last-child {
    border-right: 1px solid $c-border-light;
}

.table-fixed-column-outter--has-scroll .table-fixed-column > tbody > tr > td:last-child {
    border-right: none;
}
