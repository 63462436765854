/**
* Baker Distributing
*/

// ==============================================
// Zendesk - Mobile button
// ==============================================

#launcher {
    z-index: $z-index-8 !important;
}

@media print {
    #launcher {
        display: none !important;
    }
}

._has-modal #launcher {
    z-index: 98 !important;
}

@media (max-width: $bp-screen-s - 1px) {
    #launcher {
        left: auto !important;
        right: 10px !important;
        z-index: 98 !important;
        bottom: 10px !important;
        visibility: visible;
        margin: 0 !important;
    }

    .account-nav-before-open,
    .account-nav-open,
    .nav-before-open,
    .nav-open {
        #launcher {
            visibility: hidden;
        }
    }
}
