/**
* Baker Distributing
*/

// ==============================================
// Account Dropdown - Wrap
// ==============================================

.account-link-container {
    order: 10;
    color: $c-white;

    @media (max-width: $bp-screen-m - 1px) {
        display: none;
    }

    a {
        color: $c-white;
        text-decoration: none;
    }
}

// ==============================================
// Account Dropdown - General
// ==============================================

.header-account-nav {
    @media (max-width: $bp-screen-m - 1px) {
        .account-dropdown {
            width: 100%;
        }
    }

    .account-dropdown_content {
        .mobile-account-close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 100;
            padding: 8px;
            display: inline-block;
            cursor: pointer;
        }

        @media (min-width: $header-bp-s) {
            width: 420px;

            &:before {
                @include triangle($direction: up, $size: 8px, $color: $c-border);
                top: -8px;
                right: 22px;
            }

            &:after {
                @include triangle($direction: up, $size: 6px, $color: $c-white);
                top: -6px;
                right: 24px;
            }

            .mobile-account-close {
                display: none;
            }
        }

        @media (max-width: $header-bp-s - 1px) {
            display: block;
            transition: left 0.3s;
            height: 100%;
            left: 100%;
            overflow: auto;
            position: fixed;
            top: -4px;
            width: calc(100% - #{$active-nav-indent});

            &.dropdown-content {
                display: block;
                border: none;
            }
        }
    }
}

// ==============================================
// Account Dropdown - Sign In / Register Dropdown
// ==============================================

.signin-dropdown {
    @media (max-width: $header-bp-s - 1px) {
        padding-top: 10px;
    }

    .signin-dropdown_login-form {
        padding: $space-xl $space-base;
        border-bottom: 1px solid $c-border;

        @media (max-width: $header-bp-s - 1px) {
            padding-top: 40px;
        }

        .form-login {
            margin-bottom: 0;

            @media (max-width: $header-bp-s - 1px) {
                max-width: 100%;
            }
        }
    }

    .signin-dropdown_actions {
        background: $c-lightestgray;
        padding: $space-base;

        p {
            margin-bottom: 15px;
        }

        .signin-dropdown_connect {
            border-bottom: 1px solid $c-border-light;
        }
    }
}

// ==============================================
// Account Dropdown - User Account Dropdown
// ==============================================

.header-account-nav_customer {
    @media (min-width: $header-bp-s) {
        display: flex;
        flex-wrap: wrap;
    }

    .header-account-nav_items {
        list-style: none;

        @media (min-width: $bp-screen-m) {
            .mobile-only {
                display: none;
            }
        }

        @media (min-width: $header-bp-s) {
            flex: 1;
            padding: $space-xs 0;

            &.company-links {
                border-left: 1px solid $gray-200;
            }
        }

        @media (max-width: $header-bp-s - 1px) {
            padding: $space-xs 0;

            &.company-links {
                border-top: 1px solid $gray-200;
            }
        }

        a {
            color: $c-text;
            display: block;
            padding: $space-xs $space-base;

            @media (min-width: $header-bp-s) {
                &:hover {
                    background: $c-primary;
                    color: $c-white;
                    text-decoration: none;
                }
            }
        }
    }

    .header-account-nav_signout {
        border-top: 1px solid $gray-200;

        @media (min-width: $header-bp-s) {
            flex: 0 0 100%;
        }

        @media (max-width: $header-bp-s - 1px) {
            padding: $space-xs 0;
        }

        a {
            display: inline-block;
            vertical-align: top;
            color: rgba(0,0,0,.87);
            padding: $space-base;

            @media (max-width: $header-bp-s - 1px) {
                display: block;
                padding: $space-xs $space-base;
            }
        }
    }
}

// ==============================================
// Account Dropdown - Mobile
// ==============================================

@media (max-width: $header-bp-s - 1px) {
    .account-nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;

            &:after {
                background: $c-overlay;
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 9999;
                transition: right 0.3s;
            }
        }

        .page-wrapper {
            transition: right 0.3s;
            height: 100%;
            right: 0;
            position: relative;
        }

        .page-header {
            z-index: initial;
        }
    }

    .account-nav-open {
        body:after {
            right: calc(100% - #{$active-nav-indent});
        }

        .page-wrapper {
            right: calc(100% - #{$active-nav-indent});
        }

        .account-dropdown_content {
            box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            left: $active-nav-indent;
            z-index: 99;
        }
    }
}
