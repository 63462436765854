/**
* Baker Distributing
*/

/* Page Builder Base Style Imports */
@import 'hljs/hljs';
@import 'jarallax/jarallax';

@import 'icons';
@import 'layout';
@import 'slick/slick';
@import 'content-type/import';

.cms-content-important {
    background-color: $c-lightestgray;
    color: $c-darkgray;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    margin: 0 0 35px -20px;
    padding: 20px;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}

//
//  styles for legacy content types
//  _____________________________________________

.pagebuilder-accordion {
    //@include lib-data-accordion();
}

//
//  Responsive Classes
//  _____________________________________________

@media all and (min-width: ($bp-screen-m + 1)) {
    .pagebuilder-mobile-only {
        display: none !important;
    }
}

@media all and (max-width: $bp-screen-m) {
    .pagebuilder-mobile-hidden {
        display: none !important;
    }
}

