/**
* Baker Distributing
*/

// ==============================================
// Amasty Coupons
// ==============================================

//
//  Variables
//  --------------------------------------------
$amcoupons-arrow-icon__font-size: 12px;
$amcoupons-arrow-icon__color: #858585;
$amcoupons-remove-icon__font-size: 14px;
$amcoupons-remove-icon__color: #575855;
$amcoupons-applied-coupon__cart__background: $c-white;
$amcoupons-applied-coupon__checkout__background: #f5faff;

//
//  Helpers
//  --------------------------------------------
@mixin _am-word-break {
    word-wrap: break-word;
    word-break: break-word;
}

.amcoupons-main-container {
    .amcoupons-input .label {
        font-weight: $font-weight-normal;
    }
}

.amcoupons-applied-coupon {
    & {
        @include _am-word-break;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: $space-xs 10px;
        border: 1px solid $c-border;
        border-radius: $radius-base;
        background: $c-background;
        margin-bottom: 10px;
    }

    .amcoupons-remove {
        margin-left: auto;
        line-height: 1;

        @include lib-icon-font(
            $icon-remove,
            $_icon-font-size: $amcoupons-remove-icon__font-size,
            $_icon-font-line-height: $amcoupons-remove-icon__font-size,
            $_icon-font-color: $amcoupons-remove-icon__color,
            $_icon-font-position: before,
            $_icon-font-margin: 0,
            $_icon-font-display: inline-block
        );
    }
}

.amcoupons-applied-coupon.message.success,
.amcoupons-applied-coupon.message.warning {
    & {
        display: flex; // override '.message' property
        padding: 8px 10px 8px 46px;
        margin-bottom: 10px;
        line-height: 1.5;
    }

    .amcoupons-remove {
        display: none;
    }
}

.cart-summary .fieldset.amcoupons-main-container {
    margin-top: 5px;

    .messages {
        .message {
            border: 1px solid $c-border;
            border-radius: $radius-base;
        }
    }
}

.cart-totals tr[class='totals'] .title,
.totals.discount .title {
    @include lib-icon-font(
        $icon-down,
        $_icon-font-size: $amcoupons-arrow-icon__font-size,
        $_icon-font-line-height: $amcoupons-arrow-icon__font-size,
        $_icon-font-color: $amcoupons-arrow-icon__color,
        $_icon-font-position: after,
        $_icon-font-margin: 0 0 0 10px,
        $_icon-font-display: inline-block
    );
}

.cart-totals tr[class='totals'] .title.-active,
.totals.discount .title.-active {
    @include lib-icon-font(
        $icon-up,
        $_icon-font-size: $amcoupons-arrow-icon__font-size,
        $_icon-font-line-height: $amcoupons-arrow-icon__font-size,
        $_icon-font-color: $amcoupons-arrow-icon__color,
        $_icon-font-position: after,
        $_icon-font-margin: 0 0 0 10px,
        $_icon-font-display: inline-block
    );
}

.totals.discount {
    & {
        cursor: pointer;
    }

    .title {
        @include _am-word-break;
    }

    .discount.coupon {
        display: none;
    }

    .mark {
        max-width: 290px;
    }
}

.cart-totals {
    .title {
        @include _am-word-break;
    }

    .mark {
        max-width: 209px;
    }

    .discount.coupon {
        display: none;
    }

    tr[class='totals'] .title {
        @include _am-word-break;
    }
}

.table-wrapper tr[class='totals'] {
    cursor: pointer;
}

.cart-totals .amcoupons-total-coupons {
    & {
        display: none;
    }

    .mark {
        padding-left: 10px;
    }
}

// override magento
.cart-summary .block,
.checkout-index-index,
.paypal-express-review,
.braintree-paypal-review {
    .amcoupons-main-container .actions-toolbar > .amcoupons-apply {
        width: 100%;
        text-align: center;
    }
}

.checkout-index-index .amcoupons-main-container,
.paypal-express-review .amcoupons-main-container,
.braintree-paypal-review .amcoupons-main-container {
    & {
        display: flex;
        flex-direction: column;
    }

    .amcoupons-applied-coupon:not(.message) {
        background: $amcoupons-applied-coupon__checkout__background;
    }

    .field {
        margin: 0;
    }

    .actions-toolbar {
        margin: 0;
        width: auto;
    }

    .actions-toolbar > .amcoupons-apply {
        margin: 10px 0 0;
    }
}

// override magento
.paypal-express-review .paypal-review-discount .block .actions-toolbar .action.primary,
.braintree-paypal-review .paypal-review-discount .block .actions-toolbar .action.primary {
    @extend .action.primary;
    border-radius: $button__border-radius;
    box-shadow: none;
}

@media (min-width: $bp-screen-xs) {
    .checkout-index-index .columns .amcoupons-main-container,
    .paypal-express-review .amcoupons-main-container,
    .braintree-paypal-review .amcoupons-main-container {
        box-sizing: border-box;
        width: 100%;
    }
}


//
//  Checkout
//  --------------------------------------------
.checkout-index-index {
    .columns {
        .payment-option.discount-code .payment-option-title {
            margin: 0;
        }

        .amcoupons-main-container {
            padding: 0;

            #discount-form {
                max-width: 30rem;
            }

            .amcoupons-applied-coupon.message.success,
            .amcoupons-applied-coupon.message.warning
            {
                background: $amcoupons-applied-coupon__checkout__background;
            }

            .messages {
                .message {
                    border: 1px solid $c-border;
                    border-radius: $radius-base;
                }
            }

            .payment-option-inner {
                display: flex;
                align-items: end;

                > .field {
                    flex: 1;
                }

                > .actions-toolbar {
                    flex: 0 0 auto;
                    margin-left: 20px;

                    .amcoupons-apply {
                        margin: 0;
                    }
                }
            }
        }
    }
}
