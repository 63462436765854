/**
* Baker Distributing
*/

div[data-content-type='tabs'] {
    .tabs-navigation {
        max-width: 100%;
        overflow-x: auto;
        display: flex;
    }

    .tabs-navigation::-webkit-scrollbar {
        display: none;
    }

    li.tab-header {
        border-bottom: 2px solid $c-tab-border-bottom;
        transition: border-color $transition-base;
    }

    li.tab-header.ui-state-active{
        border-bottom: 2px solid $c-tab-border-bottom-active !important;
    }

    li.tab-header a.tab-title {
        color: $c-tab-text;
        white-space: nowrap;
        letter-spacing: $letter-spacing;
        text-transform: uppercase;
        line-height: 20px;
        padding: $space-s $space-base;
        transition: color $transition-base;
        display: block;
    }

    li.tab-header a.tab-title:hover {
        text-decoration: none;
        color: $c-tab-text-hover;
    }

    li.tab-header.ui-state-active  a.tab-title {
        color: $c-tab-text-active;
    }
}
