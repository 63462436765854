/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

 // When tables linearize for small screens
$bp-table-linearize: 700px !default;

$c-table-background: #FFF      !default;
$c-table-border:     $gray-100 !default;

$c-table-hover: $c-yellow-light !default;

$c-table-zebra-light: #FFF    !default;
$c-table-zebra-dark:  #FBFBFB !default;


// ==============================================
// Table
// ==============================================

.table {
    width: 100%;
    margin-bottom: $gap;
    background: $c-table-background;
}

.table td,
.table th {
    padding: $space-s $space-base;
    vertical-align: middle;
}

.table tbody td,
.table tbody th {
    border-top: 1px solid $c-table-border;
    border-bottom: 1px solid $c-table-border;
}

.table th {
    color: $c-text-muted;
    font-size: $font-size-s;
    white-space: nowrap;
    @include bold;
}

// Fix for checkboxes inside tables
// ----------------------------------------------

.table [type='checkbox'] + label {
    margin-left: 0;
}

// ----------------------------------------------

@media print {

    .table td,
    .table th {
        padding: $space-xxs;
    }

}


// ==============================================
// Modifier: Border
// ==============================================

.table--border {
    border: 1px solid $c-table-border;
}

.table--border th,
.table--border td {
    border: 1px solid $c-table-border;
}


// ==============================================
// Modifier: Compact
// ==============================================

.table--compact th,
.table--compact td {
    padding: 10px 8px;
}


// ==============================================
// Modifier: Hover
// ==============================================

.table--hover tbody tr:hover th,
.table--hover tbody tr:hover td {
    background: $c-table-hover;
}


// ==============================================
// Modifier: Linearize
// ==============================================

@media (max-width: $bp-table-linearize - 1px) {

    .table--linearize {
        border: 0;
    }

    .table--linearize tr,
    .table--linearize th,
    .table--linearize td {
        display: block;
    }

    .table--linearize th,
    .table--linearize td {
        border: 0;
    }

    // ----------------------------------------------
    // Head

    .table--linearize thead {
        display: none;
    }

    // ----------------------------------------------
    // Body - TR

    .table--linearize tbody tr {
        border-top: 1px solid $c-table-border;
        padding: $space-s;

        &:last-child {
            border-bottom: 1px solid $c-table-border;
        }
    }

    // ----------------------------------------------
    // Body - TD

    .table--linearize tbody td {
        padding: 0;
        border: 0;
    }

    // ----------------------------------------------
    // Foot

    .table--linearize tfoot tr {
        border-bottom: 1px solid $c-table-border;
    }

}


// ==============================================
// Modifier: Zebra Dark
// ==============================================

.table--zebra-dark tbody tr:nth-child(odd) {
    background: $c-table-zebra-dark;
}

.table--zebra-dark tbody tr:nth-child(even) {
    background: $c-table-zebra-light;
}


// ==============================================
// Modifier: Zebra Light
// ==============================================

.table--zebra-light tbody tr:nth-child(odd) {
    background: $c-table-zebra-light;
}

.table--zebra-light tbody tr:nth-child(even) {
    background: $c-table-zebra-dark;
}
