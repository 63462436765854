/**
* Baker Distributing
*/

// ==============================================
// Supersedes Table
// ==============================================

$bp-linear-supersedes: 699px;

.supersedes_product-actions > div {
    text-align: right;
}

@media (max-width: $bp-linear-supersedes) {
    .supersedes_product-actions > div {
        text-align: left;
    }
}

.supersedes_product-actions > div:first-child {
    margin-bottom: $space-xxs;
}

@media (max-width: $bp-linear-supersedes) {
    .supersedes_product-actions > div:first-child {
        margin-bottom: $space-xs;
    }
}

@media (max-width: $bp-linear-supersedes) {
    .supersedes_product-inventory {
        margin-bottom: $space-xs;
    }
}

.supersedes_type {
    width: 72px;
}

@media (max-width: $bp-linear-supersedes) {
    .supersedes_type {
        margin-bottom: $space-xs
    }
}

.supersedes_product-numbers {
    width: 140px;
}

@media (max-width: $bp-linear-supersedes) {
    .supersedes_product-numbers {
        margin-bottom: $space-xs
    }
}

.supersedes_product-image {
    width: 70px;
    min-width: 70px;
}

@media (max-width: $bp-linear-supersedes) {
    .supersedes_product-image {
        float: left;
    }
}

.supersedes_product-info {
    white-space: normal;
}

@media (max-width: $bp-linear-supersedes) {
    .supersedes_product-info {
        height: 66px;
    }
}


// ==============================================
// Supersedes Link
// ==============================================

.supersedes-link {
    background-image: $icon-supersedes;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position-x: right;
    background-position-y: 2px;
    padding-right: 22px;
}
