// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

.navigation-wrapper {
    display: flex;
    justify-content: space-between;
    z-index: 1005;

    @media (min-width: $bp-screen-m) {
        padding: 0 $space-l;
    }
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size: 28px,
        $_icon-font-line-height: 32px,
        $_icon-font-color: $c-white,
        $_icon-font-color-hover: $c-white
    );

    @include lib-icon-text-hide();
    cursor: pointer;
    font-size: 0;
    left: $space-base;
    position: absolute;
    top: 5px;
    z-index: 15;

    @media (min-width: $bp-screen-m) {
        display: none;
    }
}

.nav-sections {
    @media (max-width: $bp-screen-m - 1px) {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: calc(100% - #{$active-nav-indent});
        background: $navigation__background;
    }

    .nav-sections-items {
        position: relative;

        @media (max-width: $bp-screen-m - 1px) {
            padding-bottom: 68px;
        }
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        @media (min-width: $bp-screen-m) {
            nav.navigation,
            nav.header-top-nav {
                display: none;
            }
        }
    }

    .shop-items_title {
        a {
            display: block;
            color: $c-black;
            padding: 8px 34px 8px 16px;
            line-height: 24px;
            position: relative;

            @include lib-icon-font(
                $_icon-font-content: $icon-pointer-right,
                $_icon-font-size: 24px,
                $_icon-font-line-height: 24px,
                $_icon-font-position: after,
                $_icon-font-display: block
            );

            &:after {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                color: $c-text-muted;
            }
        }
    }

    .shop-items_content {
        display: none;
    }

    .header-top-nav {
        a {
            display: block;
            color: $c-black;
            padding: 8px 16px;
            line-height: 24px;
        }

        li.new a::after {
            content: 'New!';
            background: $c-library-yellow-4;
            padding: 0 16px;
            margin-left: 16px;
            display: inline-block;
            vertical-align: top;
            color: $gray-900;
            border-radius: 12px;
        }
    }
}

.nav-drawer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: -100%;
    background: $navigation__background;
    transition: left .3s;

    @media (min-width: $bp-screen-m) {
        display: none;
    }

    .nav-drawer_title {
        @include h2();
        padding: $navigation__indent-side;
        margin-bottom: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    }

    .nav-drawer_control {
        background: $c-secondary;
        color: $c-white;
        padding: $space-s $space-base;
        letter-spacing: 0.04em;
        cursor: pointer;

        @include lib-icon-font(
            $_icon-font-content: $icon-arrow-left-thin,
            $_icon-font-size: 24px,
            $_icon-font-display: block,
            $_icon-font-margin: -3px 10px 0 0
        );
    }

    .nav-drawer_content {
        padding-bottom: 68px;

        ul li a {
            display: block;
            color: $c-black;
            padding: 8px 40px 8px 16px;
            line-height: 24px;
        }

        li.featured-links {
            background: $gray-100 ;
            border-top: 1px solid $gray-200;
            border-bottom: 1px solid $gray-200;
            padding: $space-xs 0;
            margin-top: $space-xs;
        }
    }
}

.nav-before-open {
    @media (max-width: $bp-screen-m - 1px) {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }
    }
}

.nav-open {
    @media (max-width: $bp-screen-m - 1px) {
        .page-wrapper {
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            left: 0;
            z-index: 1000;
        }

        .nav-toggle {
            z-index: 101;

            &:after {
                background: $c-overlay;
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }
}
