/**
* Baker Distributing
*/

// ==============================================
// Amasty Custom Forms
// ==============================================

.rendered-form .amform-form-fieldset.fieldset {
    .fields .field.form-group {
        margin-bottom: 30px;

        > label {
            display: inline-block;
            vertical-align: top;
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: normal;
        }
    }

    input.form-control,
    input.amform-date,
    input.amform-time {
        appearance: none;
        background: transparent;
        background-clip: padding-box;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $gray-600;
        height: 38px;
        padding: 0;
        width: 100%;
        outline: 0;
        transition: all 0.2s ease;

        &.mage-error {
            border-color: $red-700;
        }
    }

    textarea.form-control {
        width: 100%;
        height: 100px;
    }

    input[type="number"] {
        text-align: center;
        width: 100px;
    }

    input.amform-date {
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsLW9wYWNpdHk9IjAuNTQiIGQ9Ik0yMCAzaC0xVjFoLTJ2Mkg3VjFINXYySDRjLTEuMSAwLTIgLjktMiAydjE2YzAgMS4xLjkgMiAyIDJoMTZjMS4xIDAgMi0uOSAyLTJWNWMwLTEuMS0uOS0yLTItMnptMCAxOEg0VjhoMTZ2MTN6Ii8+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjwvc3ZnPgo=) no-repeat right;
        background-size: 20px;
    }

    select {
        &[multiple="true"] {
            overflow-y: auto;
            padding: 0;

            option {
                padding: 5px 0;
            }
        }

        &:focus {
            border-bottom-color: $gray-600;
        }
    }

    .amform-rating-container {
        .amform-versiontwo-label {
            pointer-events: auto;

            &:before, &:after {
                display: none;
            }
        }
    }
}
