/**
* Baker Distributing
*/

.order-owner-filter {
    position: absolute;
    margin-top: -60px;
}

// ==============================================
//  Order View - Totals
// ==============================================


.order-view_totals td {
    padding-right: $space-xs !important;
}

.order-view_totals th {
    padding-left: $space-xs;
}

.order-view_totals .grand_total {
    background: $c-lightestgray;
}

.order-view_totals .grand_total th,
.order-view_totals .grand_total td {
    color: $c-text;
    font-weight: $font-weight-bold;
    padding-top: $space-xxs;
    padding-bottom: $space-xxs !important;
}

.fee_breakdown th {
    font-weight: $font-weight-normal;
    padding-left: $space-base;
}

.fee_breakdown td {
    font-weight: $font-weight-normal;
    padding-right: $space-base !important;
    font-weight: $font-weight-light;
}
