/**
* Baker Distributing
*/


// ==============================================
// Current Store
// ==============================================

.header-store {
    display: inline-block;
    color: $c-contrast;

    @media (min-width: $bp-screen-m) {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }
}

// ==============================================
// Current Store - Container
// ==============================================

.header-store_container {
    width: auto;
    display: block;
    background: transparent;
    border: none;

    @media (max-width: $bp-screen-m - 1px) {
        position: static !important;
    }
}

// ==============================================
// Current Store - Toggle
// ==============================================

.header-store_toggle {
    width: 100%;
    position: relative;

    &:after {
        position: absolute;
        right: 0;
        top: 14px;
        margin-top: 5px;
    }

    p {
        margin: 0;
        font-weight: 400;
    }

    @media (min-width: $bp-screen-m) {
        padding: 0 28px 0 0;
    }

    @media (max-width: $bp-screen-m - 1px) {
        position: absolute;
        left: 20px;
        top: 52px;
        z-index: 100;
        width: auto;

        @include lib-icon-font(
            $_icon-font-content     : $icon-location,
            $_icon-font-size        : 26px,
            $_icon-font-line-height : 33px,
            $_icon-font-text-hide   : false,
            $_icon-font-position    : before,
            $_icon-font-display     : block,
            $_icon-font-color       : $c-black
        );

        p, &:after {
            display: none !important;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {

    .header-store_container .dropdown-content:before,
    .header-store_container .dropdown-content:after {
        border: none;
    }

}


// ==============================================
// Current Store - Dropdown
// ==============================================

.header-store_dropdown {
    display: none;
    padding: 20px;
    color: $c-text;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_wrapper .header-store_dropdown {
        left: 0;
        right: 0;
        top: 100%;
        width: 100%;
        margin-top: 0;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .header-store_wrapper .dropdown {
        position: static;
    }

    .header-store_wrapper .dropdown-content {
        width: 100%;
        left: 0;
        right: 0;
        margin-top: 0;
    }
}

.header-store_dropdown::before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    right: 22px;
}

.header-store_dropdown::after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    right: $space-xl;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_dropdown::after,
    .header-store_dropdown::before {
        border: none;
    }
}


// ==============================================
// Current Store - Name
// ==============================================

.store-name {
    .mobile-label {
        margin: 0;
    }

    @media (min-width: $bp-screen-m) {
        white-space: nowrap;

        .mobile-label {
            display: none;
        }
    }
}

// ==============================================
// Current Store - Phone
// ==============================================

.store-phone {
    margin-bottom: $space-xxs;
}

@media (max-width: $bp-screen-m - 1px) {
    .store-phone {
        margin-bottom: $space-s;
    }
}

// ==============================================
// Current Store - Text2Baker
// ==============================================

.store-text .text-opt-link {
    line-height: 16px;
}

// ==============================================
// Current Store - Hours
// ==============================================

.store-hours {
    margin: $space-s 0;
}

.store-hours .open {
    color: $green-500;
}

.store-hours .closed {
    color: $c-red;
}


// ==============================================
// Current Store - Distance
// ==============================================

.store-distance {
    padding-bottom: $space-base;
}


// ==============================================
// Current Store - Address
// ==============================================

.store-address {
    padding: 10px 0 0;
}
