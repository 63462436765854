/**
* Baker Distributing
*/

// ==============================================
// Warranty Lookup - Wrap
// ==============================================

.warranty-wrap {
    @media (min-width: $bp-screen-m) {
        max-width: $bp-screen-l;
        margin: $space-xxxl auto 0;
    }

    .loader-wrap {
        margin: $space-xxxl 0;
    }
}

// ==============================================
// Warranty Lookup - Banner
// ==============================================

.warranty-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: $space-xxxl;
    background: $c-brand;
    color: $c-white;
    padding: $space-xxl $space-xl;
    margin-bottom: $space-xl;

    @media (max-width: $bp-screen-m - 1px) {
        column-gap: $space-xl;
    }

    @media (max-width: $bp-screen-xs - 1px) {
        padding: $space-xl;
    }

    .img-wrap {
        flex: 0 0 160px;

        @media (max-width: $bp-screen-m - 1px) {
            flex: 0 0 120px;
        }

        @media (max-width: $bp-screen-xs - 1px) {
            flex: 0 0 100px;
        }
    }

    h1 {
        font-weight: $font-weight-semibold;
        color: $c-white;
        margin: 0;
        font-size: 5rem;

        @media (max-width: $bp-screen-l - 1px) {
            font-size: 4rem;
        }

        @media (max-width: $bp-screen-m - 1px) {
            font-size: 2.6rem;
        }

        @media (max-width: $bp-screen-s - 1px) {
            span {
                display: block;
            }
        }

        @media (max-width: $bp-screen-xs - 1px) {
            font-size: 2.4rem;
        }
    }

    + p {
        font-size: $font-size-l;
        margin-bottom: $space-xxxl;

        @media (max-width: $bp-screen-xs - 1px) {
            font-size: $font-size-base;
        }
    }
}

// ==============================================
// Warranty Lookup - Form
// ==============================================

#warranty-lookup-form {
    margin-bottom: $space-xxxl;

    @media (min-width: $bp-screen-s) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: $space-xl;
    }

    > .field {
        @media (min-width: $bp-screen-s) {
            flex: 1;
        }

        div.mage-error {
            color: $c-input-focus-error;
            margin-top: $space-xxs;
        }
    }

    > .actions {
        flex: 0 0 100%;
        display: flex;
        justify-content: flex-end;
        column-gap: 32px;

        @media (max-width: $bp-screen-s - 1px) {
            flex-wrap: wrap;
            row-gap: $space-base;
            column-gap: 0;

            button {
                flex: 1 1 100%;

                &.btn-submit {
                    order: 1;
                }

                &.btn-reset {
                    order: 2;
                    box-shadow: $button-box-shadow;
                }
            }
        }

        .message {
            flex: 1;
            margin: 0;

            @media (max-width: $bp-screen-s - 1px) {
                flex: 1 1 100%;
                order: 3;
            }
        }
    }
}

// ==============================================
// Warranty Lookup - Content
// ==============================================

.warranty-content {
    > div {
        margin-bottom: $space-xxxl;
    }

    table {
        margin-bottom: 0;

        thead {
            tr {
                background: $c-background;
            }
        }

        tbody {
            @media (min-width: $bp-table-linearize) {
                td.label {
                    width: 1px;
                    white-space: nowrap;
                }
            }

            td .mobile-label {
                display: block;

                @media (min-width: $bp-table-linearize) {
                    display: none;
                }
            }
        }

        @media (max-width: $bp-table-linearize - 1px) {
            &.table-w-thead {
                tbody {
                    tr td:not(:last-child) {
                        margin-bottom: $space-xxs;
                    }
                }
            }
        }
    }
}

// ==============================================
// Warranty Lookup - Details
// ==============================================

.warranty-details {
    .pdp-link {
        margin-left: $space-l;
        height: auto;
        font-size: $font-size-xs;
        padding: $space-xxs $space-xs;

        @media (max-width: $bp-screen-s - 1px) {
            margin: $space-xs 0 0 0;
            display: block;
        }
    }
}

.warranty-details-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.75em;
    font-size: 20px;
    column-gap: $space-base;

    h2 {
        margin: 0;
    }
}

// ==============================================
// Warranty Lookup - Service History
// ==============================================

.warranty-history {
    ul li {
        margin-bottom: $space-xl;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .service-entry {
        box-shadow: $box-shadow-thin;

        h4 {
            margin: 0;
            padding: $space-base;
            background: $c-background;
            cursor: pointer;
            justify-content: space-between;

            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 22px,
                $_icon-font-position: after,
                $_icon-font-display: flex
            );
        }

        > div {
            display: none;
        }

        &.active {
            h4 {
                @include lib-icon-font(
                    $_icon-font-content: $icon-up,
                    $_icon-font-size: 22px,
                    $_icon-font-position: after,
                    $_icon-font-display: flex
                );
            }

            > div {
                display: block;
            }
        }

        table {
            tr:first-child th,
            tr:first-child td {
                border-top: none;
            }
        }

        .replacement-parts-table-wrap {
            @media (min-width: $bp-table-linearize) {
                padding: $space-base;
            }

            @media (max-width: $bp-table-linearize - 1px) {
                table tbody tr:first-child {
                    border-top: none;
                }
            }

            > table thead tr {
                background: $c-background;
            }
        }
    }
}

// ==============================================
// Warranty Lookup - Print Page
// ==============================================

.warranty-result-printdetails {
    .columns {
        margin-bottom: $space-xxxl;
    }

    .page-title-wrapper .page-title {
        font-weight: $font-weight-normal;
        margin-bottom: 24px;
    }

    .logo-print {
        text-align: center;

        img {
            display: inline-block;
            vertical-align: top;
        }
    }

    .warranty-history ul li h3 {
        margin-bottom: $space-xs;
    }

    @media print {
        body,
        button,
        input,
        table,
        textarea {
            font-size: $font-size-xs;
            line-height: $line-height-xs;
        }

        .table td, .table th {
            padding: $space-xxs $space-xs;
        }

        .warranty-history ul li h3 {
            font-weight: $font-weight__heavier;
        }

        .replacement-parts-table-wrap .table th {
            font-size: $font-size-xs;
            line-height: $line-height-xs;
        }
    }
}
